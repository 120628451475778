import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import moment from "moment"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Actions as TripActions } from 'redux/features/trips'

import Footer from "components/common/Footer"
import Spinner from "components/common/Spinner"
import Navigation, { NavColor } from "components/navbar/Navigation"

import { TicketStatus } from "components/pages/Trips/constants"
import TripCard, { TripCardType } from "components/pages/Trips/TripCard"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"
import {filterStaleItineraries} from "components/pages/Trips/utils";


const Trips = () => {
    const dispatch = useDispatch()
    const trips = useSelector((state: any) => state.trips.trips)
    filterStaleItineraries(trips)
    const isLoadingTrips = useSelector((state: any) => state.trips.isLoading)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    const cancelledTrips = trips.filter((trip: any) => trip.ticketStatus === TicketStatus.CANCELLED || trip.ticketStatus === TicketStatus.UNAVAILABLE)
    const validTrips =  trips.filter((trip: any) => trip.ticketStatus !== TicketStatus.ERROR && trip.ticketStatus !== TicketStatus.UNATTEMPTED_ERROR && trip.ticketStatus !== TicketStatus.TICKETING_ERROR && trip.ticketStatus !== TicketStatus.CANCELLED && trip.ticketStatus !== TicketStatus.UNAVAILABLE)
    const futureTrips = validTrips.filter((trip: any) =>
        moment(trip.reservations[0]?.itineraries[0]?.departureTimeDate).isSameOrAfter(moment())).sort((trip: any) => trip.reservations[0]?.itineraries[0]?.departureTimeDate
    )
    const pastTrips = validTrips.filter((trip: any) => moment(trip.reservations[0]?.itineraries[0]?.departureTimeDate).isBefore(moment())).sort((trip: any) => trip.reservations[0]?.itineraries[0]?.departureTimeDate)

    useEffect(() => {
        trips.length <= 1 && dispatch(TripActions.fetchTrips())
    }, []) // eslint-disable-line

    return <Container>
        <div className="d-flex flex-column">
            <div style={{minHeight: '100dvh', display: 'flex', flexDirection: 'column'}}>
                <Navigation color={NavColor.Black}/>
                <AnimatePresence mode='wait'>
                    {<Content key='trips' {...fadeInOutMotionProps}>
                        <TripSection>
                            <Title>Trips</Title>
                            {isLoadingTrips ? <LoadingContainer key='spinner' {...fadeInOutMotionProps}>
                                <Spinner size={40}/>
                            </LoadingContainer> : (futureTrips.length ? futureTrips.map((trip: any) => <TripCard key={trip.uuid} trip={trip} type={TripCardType.Full}/>) : <EmptyContentContainer>You have no upcoming trips.</EmptyContentContainer>)}
                        </TripSection>
                        <TripSection>
                            <Title>Past Reservations</Title>
                            {isLoadingTrips ? <LoadingContainer key='spinner' {...fadeInOutMotionProps}>
                                    <Spinner size={40}/>
                            </LoadingContainer> : (isMobile ? (pastTrips.length ? pastTrips.map((trip: any) => <TripCard key={trip.uuid} trip={trip} type={TripCardType.Small}/>) : <EmptyContentContainer>You have no past trips.</EmptyContentContainer>) : <TripGrid>
                                {(pastTrips.length ? pastTrips.map((trip: any) => <TripCard key={trip.uuid} trip={trip} type={TripCardType.Small}/>) : <EmptyContentContainer>You have no past trips.</EmptyContentContainer>)}
                            </TripGrid>)}
                        </TripSection>
                        <TripSection>
                            <Title>Canceled trips</Title>
                            {isLoadingTrips ? <LoadingContainer key='spinner' {...fadeInOutMotionProps}>
                                <Spinner size={40}/>
                            </LoadingContainer> : (isMobile ? (cancelledTrips.length ? cancelledTrips.map((trip: any) => <TripCard key={trip.uuid} trip={trip} type={TripCardType.Canceled}/>) : <EmptyContentContainer>You have no cancelled trips.</EmptyContentContainer>) : <TripGrid>
                                {cancelledTrips.length ? cancelledTrips.map((trip: any) => <TripCard key={trip.uuid} trip={trip} type={TripCardType.Canceled}/>) : <EmptyContentContainer>You have no cancelled trips.</EmptyContentContainer>}
                            </TripGrid>)}
                        </TripSection>
                    </Content>}
                </AnimatePresence>
            </div>
            <Footer/>
        </div>
    </Container>    
}

const EmptyContentContainer = styled.div`
    width: 100px;
    margin: ${styles.Spacing.S} 0;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    opacity: 0.5;
`

const TripGrid = styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
`

const Content = styled(motion.div)`
    margin: ${styles.Spacing.M} 0;
    flex: 1;
    display: flex;
    flex-direction: column;
`

const TripSection = styled.div`
    margin: 0 ${styles.Spacing.M};
    padding: ${styles.Spacing.S} 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    &:not(&:last-child) {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Title = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Medium};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: ${styles.Spacing.S};
`

export default Trips