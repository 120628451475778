import React from "react"
import { useSelector } from "react-redux"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import Spinner from "components/common/Spinner"

import messages from "components/pages/Funding/messages"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"
import { useAccountTypeInformation } from "hooks/useAccountTypeInformation"

const AccountInfo = () => {
    // Redux state
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const isLoadingUser = useSelector((state: any) => state.currentUser.isFetching)
    const banking = useSelector((state: any) => state.banking)
    const debitMessage = (<div>Use the information below for both ACH transfers and wire transfers into your account.</div>)
    const creditMessage = (<div>Use this information below to make payments to your credit card via both ACH and wire transfers.</div>)
    const { isSelectedAccountDebit } = useAccountTypeInformation();
    const message = isSelectedAccountDebit ? debitMessage : creditMessage;
    let bankFirstName = currentUser?.firstName;
    let bankLastName = currentUser?.lastName;

    if (currentUser.parentUser !== null) {
      bankFirstName = currentUser.parentUser?.firstName;
      bankLastName = currentUser.parentUser?.lastName;
    }

    return <Container>
        <Description>
            {message}
        </Description>
        <AnimatePresence mode="wait">
            {isLoadingUser ? <SpinnerContainer key='spinner' {...fadeInOutMotionProps}>
                <Spinner size={40}/>
            </SpinnerContainer> : <motion.div key='accountInfo' {...fadeInOutMotionProps}>
                <Row>
                    <strong>{messages.AccountInfo.Labels.BankName}</strong>
                    <div>{messages.AccountInfo.BankNames.Stearns}</div>
                </Row>
                <Row>
                    <strong>{messages.AccountInfo.Labels.BankAddress}</strong>
                    <div>{messages.AccountInfo.BankAddresses.Stearns}</div>
                </Row>
                <Row>
                    <strong>{messages.AccountInfo.Labels.ABARoutingNumber}</strong>
                    <div>{banking.account.routingNumber}</div>
                </Row>
                <Row>
                    <strong>{messages.AccountInfo.Labels.AccountNumber}</strong>
                    <div>{banking.account.accountNumber}</div>
                </Row>
                <Row>
                    <strong>{messages.AccountInfo.Labels.NameOnAccount}</strong>
                    <div>{`${bankFirstName} ${bankLastName}`}</div>
                </Row>
                <Row>
                    <strong>{messages.AccountInfo.Labels.SwiftCode}</strong>
                    <div>STLIUS42TAE</div>
                </Row>
                {isSelectedAccountDebit && <Row>
                    <strong>{messages.AccountInfo.Labels.PeerTransferCode}</strong>
                    <div>{banking.account.uuid?.slice(-8)}</div>
                </Row>}
            </motion.div>}
        </AnimatePresence>
    </Container>
}

const SpinnerContainer = styled(motion.div)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Description = styled.div`
    flex: 0;
    height: min-content;
    align-self: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 ${styles.Spacing.XS};
    font-size: ${styles.Font.Size.Small};
    background-color: #DFDFDF;
    border-radius: 8px;
    padding: ${styles.Spacing.XS};
    margin: ${styles.Spacing.S} 0;
`

const Row = styled.div`
    flex: 1;
    padding: ${styles.Spacing.XS};
    display: flex;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        align-items: center;
        justify-content: space-between;
    }
    ${styles.MediaQueries.Mobile} {
        height: 100px;
        flex-direction: column;
        justify-content: space-between;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${styles.Spacing.M};
`

export default AccountInfo