import API from 'services/api'
import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import styled from 'styled-components'

// import classNames from 'classnames'

import { FaRegTimesCircle } from 'react-icons/fa'

// import OldHome from './home/Home'
// import Travel from './travel/travel'
// import FlightSearch from './flights/flightSearch/FlightSearch'
// import FlightBook from './flights/flightBook/FlightBook'
// import FlightConfirm from './flights/FlightConfirm'
// import Merchandise from './merchandise/Merchandise'
// import Donate from './donate/Donate'
// import Profile from './profile/Profile'
// import Trips from './trips/Trips'
// import RewardsActivity from './rewards/RewardsActivity'
// import TripSummary from './trips/TripSummary'
// import Settings from './settings/Settings'

// import CardAppRouter from './card/cardAppRouter'

import Session from 'services/session'

import FlightSearch from 'components/pages/Rewards/Flights/FlightSearch/FlightSearch'
import FlightBooking from 'components/pages/Rewards/Flights/FlightBooking/FlightBooking'
import FlightConfirmation from 'components/pages/Rewards/Flights/FlightConfirmation'
import Funding from 'components/pages/Funding/Funding'
import Home from 'components/pages/Home/Home'
import Trips from 'components/pages/Trips/Trips'
import TripInspector from 'components/pages/Trips/TripInspector'
import OldTripInspector from 'components/pages/Trips/TripInspector.old'
import Rewards from 'components/pages/Rewards/Rewards'
import RewardsActivity from 'components/pages/Rewards/RewardsActivity'
import Settings from 'components/pages/Settings/Settings'
import UserAccessInspector from 'components/pages/Settings/UserAccessInspector'
import Cards from 'components/pages/Cards/Cards'
import CardInspector from 'components/pages/Cards/CardInspector'
import Modal from 'components/common/Modal'

import ArchivedAccountNotification from 'components/common/archivedAccountNotification'
import Button, { ButtonSize, ButtonType } from 'components/common/Button'
import NoCardAccount from 'components/common/noCardAccount'
// import TestOverlay from 'components/common/testOverlay'
// import MSOverlay from 'components/common/msOverlay'
import LoadingSpinner from 'components/common/loadingSpinner'
import Toast from 'components/common/Toast/Toast'
// import NavbarComponent from 'components/navbar/navbar'
// import Subnav from 'components/navbar/subnav'


import { AppPath } from 'components/appRouter/constants'
import ErrorBoundary from 'components/ErrorBoundary'
import Error404 from 'components/errors/404'
import Error500 from 'components/errors/500'

import { Actions } from 'redux/currentUser'
import { Actions as BankingActions } from 'redux/features/banking/banking'
import { updateDropdownKey } from 'redux/features/global/globalSlice'
import { DropdownKeys } from 'redux/features/global/constants'
import { updateToast } from "redux/features/global/globalSlice";

import { isFeatureEnabled, ToggleFeature } from 'utils/toggles'

import styles from 'styles/styles'

import 'react-datepicker/dist/react-datepicker.css'

import 'assets/utils/buttons.scss'
import 'assets/utils/typeography.scss'
import 'assets/global/css/containerImage.scss'
import 'assets/global/css/reactChatStyles.scss'

const redirect = (pathname) => {
  // If user is going to the old statements page (from an email) redirect them to the new page
  if (pathname === '/card/statements/') {
    return <Redirect to={`/funding/statements`} />
  }

  // If user is going to the trip inspector, redirect them to the new page
  if (pathname.split('/')[1] === 'trip') {
    return <Redirect to={pathname.replace('trip', 'trips')} />
  }
}

class AppRouter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPathname: null,
      previousPathname: null,
    }

    const fiveMinutes = 1000 * 60 * 5 // 1000 ms = 1 second * 60 = 1 minute * 5
    const refreshToken = localStorage.getItem('refreshToken')
    console.log('refreshToken', refreshToken)
    const refreshTokenExpTime = refreshToken !== 'undefined' && JSON.parse(window.atob(refreshToken?.split('.')[1]))['exp'] * 1000
    const remainingTokenLifetime = refreshTokenExpTime - Date.now()
    // Show refresh modal when refresh token is < 5 min from expiring
    if (remainingTokenLifetime < fiveMinutes) {
      this.props.dispatch(updateToast({
        message: 'Your session is expiring soon.',
        isError: false,
        isOpen: true,
      }))  
    } else {
      setTimeout(() => {
        this.props.dispatch(updateToast({
            message: 'Your session is expiring soon.',
            isError: false,
            isOpen: true,
        }))
      }, remainingTokenLifetime - fiveMinutes)
    }
    // Log user out when token expires
    setTimeout(() => {
      Session.logout()
      window.location.replace('/login/')
    }, remainingTokenLifetime)
  }

  closeDropdowns = () => {
    this.props.dropdownKey !== DropdownKeys.Closed &&
      this.props.dispatch(updateDropdownKey(DropdownKeys.Closed))
  }

  componentDidMount() {
    this.setState({ currentPathname: this.props.location.pathname })
    this.props.dispatch(Actions.fetchCurrentUser())

    this.fetchRecentCardAccount()
  }

  fetchRecentCardAccount() {
    // If the current page is the card inspector, use the relevant card account instead of the user's default
    const isCardInspectorRoute =
      /\/cards\//.test(this.props.location.pathname) && this.props.location.pathname.split('/')[2]
    if (isCardInspectorRoute) {
      const cardAccountUuid = this.props.location.pathname.split('/')[2]
      this.props.dispatch(BankingActions.fetchCardAccount({ cardAccountUuid }))
    } else {
      const recentAccountUuid = localStorage.getItem('recentAccountUuid')
      // Check if last accessed account is stored in localstorage, otherwise load default account
      if (recentAccountUuid && recentAccountUuid !== 'undefined') {
        this.props.dispatch(BankingActions.fetchCardAccount({ cardAccountUuid: recentAccountUuid }))
      } else {
        this.props.dispatch(BankingActions.fetchCardAccount({ enforceDefault: true }))
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.previousPathname) {
      return {
        currentPathname: nextProps.location.pathname,
        previousPathname: prevState.currentPathname,
      }
    } else return null
  }

  render() {
    const { userLoading, error, currentUser, banking, location } = this.props
    const { hasCardAccount, hasAuthorizedUserAccount, application, isClosedAccount, twoFaEnabled } =
      currentUser

    const production = window.env.REACT_APP_DEBUG === 'false'

    const isAuthorizedUsersEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.AuthorizedUsers)
    const isFlightBookRedesignEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.FlightBookingRedesign)
    const isTripRedesignEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.TripsRedesign)
    const isErrorLoadingUserOrBanking = error || banking.error

    if (userLoading && !error) {
      return <LoadingSpinner />
    }

    if (redirect(location.pathname)) {
      return redirect(location.pathname)
    }

    if (error) {
      if (Object.values(AppPath).includes(location.pathname)) {
        localStorage.setItem('redirect', location.pathname)
      }
      return <Redirect to='/login' />
    }

    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              // Fetch your jwt token and then call our supplied callback below.
              API.auth.getZendeskJWT().then((res) => {
                const jwt = res.data.token
                callback(jwt)
              })
            },
          },
        },
      },
    }

    if (
      (!hasCardAccount && !hasAuthorizedUserAccount) ||
      application === null ||
      (application != null && application.currentStage === 'DENIED') ||
      isClosedAccount ||
      (production && twoFaEnabled === false)
    ) {
      return <NoCardAccount application={application} isClosedAccount={isClosedAccount} />
    }

    return (
      <div className="AppRouter">
        <Helmet>
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=ed322d9a-23d8-4baf-b9af-4a4d95ead462"
          />
        </Helmet>
        <ErrorBoundary {...this.props}>
          <ArchivedAccountNotification account={banking.account} />
          <div id="ContainerImage">
            <Toast />
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} />} />
              <Route exact path="/rewards" render={(props) => <Rewards {...props} />} />
              <Route
                exact
                path="/rewards-activity"
                render={(props) => <RewardsActivity {...props} />}
              />
              <Route exact path="/rewards/flights" render={(props) => <FlightSearch {...props} />} />
              <Route
                exact
                path="/rewards/confirm"
                render={(props) => isFlightBookRedesignEnabled ? <FlightBooking {...props} /> : <FlightConfirmation {...props}/>}
              />
              <Route exact path="/funding/:tab?" render={(props) => <Funding {...props} />} />
              <Route exact path="/trips" render={(props) => <Trips {...props} />} />
              <Route exact path="/trips/:tripID" render={(props) => isTripRedesignEnabled ? <TripInspector {...props} /> : <OldTripInspector {...props} />} />
              <Route exact path="/cards" render={(props) => <Cards {...props} />} />
              <Route exact path="/settings/:tab?" render={(props) => <Settings {...props} />} />
              {isAuthorizedUsersEnabled && (
                <Route
                  exact
                  path="/settings/useraccess/:cardAccountID/:userID?"
                  render={(props) => <UserAccessInspector {...props} />}
                />
              )}
              <Route
                exact
                path="/cards/:cardAccountID/:cardID"
                render={(props) => <CardInspector {...props} />}
              />
              {/* <Route
                path="*"
                render={(props) => <PageNotFound {...props}/>}
              /> */}
              {/* <Route
                exact
                path="/"
                render={(props) => {
                  return (
                    <StdContainer>
                      <Home {...props} />
                    </StdContainer>
                  )
                }}
              />
              <Route
                exact
                path="/rewards/travel/flights/"
                render={(props) => (
                  <FixedContainer>
                    <FlightSearch {...props} />
                  </FixedContainer>
                )}
              />
              <Route
                exact
                path="/rewards/travel/flights/confirmation/"
                render={(props) => (
                  <FixedContainer>
                    <FlightConfirm {...props} />
                  </FixedContainer>
                )}
              />
              <Route
                exact
                path="/rewards/travel/flights/book/"
                render={(props) => (
                  <FixedContainer>
                    <FlightBook {...props} />
                  </FixedContainer>
                )}
              />
              <Route
                exact
                path="/shop/"
                render={(props) => (
                  <StdContainer>
                    <Merchandise {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/donate/"
                render={(props) => (
                  <StdContainer>
                    <Donate {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/trips/"
                render={(props) => (
                  <StdContainer>
                    <Trips {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/trip/:tripId"
                render={(props) => (
                  <StdContainer>
                    <TripSummary {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/profile/"
                render={(props) => (
                  <StdContainer>
                    <Profile {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/rewards/"
                render={(props) => (
                  <StdContainer>
                    <Travel {...props} />
                  </StdContainer>
                )}
              />
              <Route
                path="/card"
                render={(props) => (
                  <StdContainer>
                    <CardAppRouter {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/settings/"
                render={(props) => (
                  <StdContainer>
                    <Settings {...props} />
                  </StdContainer>
                )}
              />
              <Route
                exact
                path="/rewards/activity/"
                render={(props) => (
                  <StdContainer>
                    <RewardsActivity {...props} />
                  </StdContainer>
                )}
              />*/}
              <Route render={() => <Error404 />} />
              <Route render={() => <Error500 />} />
            </Switch>
            {/* {isMser && <MSOverlay />}
            {isTestAccount && <TestOverlay />} */}
          </div>
        </ErrorBoundary>
        {isErrorLoadingUserOrBanking && <Modal title='Loading Error' titleIcon={<FaRegTimesCircle className='w-100 h-auto' fill='red'/>}>
          <AccountErrorModalContent>
            <div>We encountered an issue loading your account.</div>
            <div>Please refresh the page or contact <span style={{color: styles.Color.TaekusPurple}}>support@taekus.com</span> if the problem persists.</div>
            <div className='w-100 d-flex justify-content-end'>
              <ModalButtonContainer>
                <Button
                  size={ButtonSize.Wide}
                  buttonType={ButtonType.Purple}
                  label='Refresh Page'
                  onClick={() => { window.location.reload() }}
                />
              </ModalButtonContainer>
            </div>
          </AccountErrorModalContent>
        </Modal>}
      </div>
    )
  }
}

const ModalButtonContainer = styled.div`
  ${styles.MediaQueries.Desktop} {
    width: 200px;
    min-width: min-content;
    display: flex;
    justify-content: end;
  }
  ${styles.MediaQueries.Mobile} {
    width: 100%;
  }
`

const AccountErrorModalContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${styles.Font.Family.MonumentGrotesk};
  font-size: 16px;
  ${styles.MediaQueries.Desktop} {
    max-width: 460px;
  }
  * {
    &:not(&:last-child) {
      margin-bottom: 20px;
    }
  }
`

const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.global,
  banking: state.banking,
})

export default connect(mapStateToProps, null)(AppRouter)
