declare global {
    interface Window {
        ioGetBlackbox: () => { blackbox: string };
    }
}

/**
 * Gets the iovation blackbox key from the window object.
 * This requires the iovation script to be loaded in App.js.
 * 
 * @returns {string | null} The iovation blackbox key or null if there was an error
 */
export const getIovationBlackboxKey = (): string | null => {
    let bb3 = null;

    try {
        if (typeof window.ioGetBlackbox !== 'function') {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Iovation script not loaded - ioGetBlackbox not found');
            }
            return null;
        }
        
        bb3 = window.ioGetBlackbox().blackbox;
    } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Error getting iovation blackbox key:', error);
        }
    }

    return bb3;
}; 