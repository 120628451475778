import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"


import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import Button, { ButtonSize, ButtonType } from "components/common/Button"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { LinkText } from "components/pages/Settings/constants"
import { Actions as TransferActions } from 'redux/features/banking/transfers'

import NumberFormat from 'react-number-format'

import { ReactComponent as RadioSVG } from "assets/svg/Radio.svg";
import Checkbox from "components/common/Checkbox"

import { PaymentOption } from "../types"
import { PaymentDetailRow } from "./PaymentDetailRow"
import { ActionSection, Container, StatusContainer } from "../styles"
import { getAccountName } from "../../util"
import StatusDisplay from "components/common/StatusDisplay"
import SuccessBanner from "components/common/SuccessBanner"
import Spinner from "components/common/Spinner"
import { LinkedAccount } from "types/LinkedAccount"

const paymentDescriptions = {
    [PaymentOption.TotalBalance]: 'Total Balance',
    [PaymentOption.MinimumPayment]: 'Minimum Payment',
    [PaymentOption.Other]: 'Other Amount',
} as const

type ReviewStepProps = {
    amount: string
    sourceAccount?: LinkedAccount
    paymentDate: moment.Moment
    decrementStep: () => void
    incrementStep: () => void
    selectedOption: PaymentOption
    resetForm: () => void
}

const CreditPaymentReviewForm = (props: ReviewStepProps) => {
    const dispatch = useDispatch()
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isAwaitingTransfer, setIsAwaitingTransfer] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { amount, sourceAccount, paymentDate, incrementStep, decrementStep, selectedOption, resetForm } = props;

    const isCancellable = !sourceAccount?.isInternalAccount;

    const selectedAccountName = useSelector((state: any) => {
        const accountUuid = state.banking.account.uuid;
        const account = state.linkedAccounts.internalAccounts.find((account: any) => account.uuid === accountUuid);
        return account ? `${getAccountName(account)} -${account.accountLast4}` : '';
    });

    const selectedAccount = useSelector((state: any) => state.banking.account);
    const isLoading = useSelector((state: any) => state.transfers.newTransferPending);
    const createError = useSelector((state: any) => state.transfers.createError);
    const errors = useSelector((state: any) => state.transfers.errors);
    const newTransferPending = useSelector((state: any) => state.transfers.newTransferPending);

    const isMobile = useSelector((state: any) => state.global.isMobile);

    const cancelTransferData = useSelector((state: any) => state.transfers.cancelTransfer)
    const newTransferUuid = useSelector((state: any) => state.transfers.newTransferUuid)

    const handleSubmitPayment = () => {
        setIsAwaitingTransfer(true);
        const formattedAmount = String(Number(amount.replaceAll(',', '')).toFixed(2))
        dispatch(TransferActions.createTransfer({
            transferDirection: 'inbound',
            transferDate: paymentDate.format('YYYY-MM-DD'),
            transferValue: formattedAmount,
            externalAccountUuid: sourceAccount?.uuid,
            isInternalAccount: sourceAccount?.isInternalAccount,
            cardAccountUuid: selectedAccount?.uuid,
            autoReload: false,
            acceptTerms: true
        }))
    }

    // Add effect to handle transfer completion
    useEffect(() => {
        if (isAwaitingTransfer && newTransferPending === false) {
            if (!createError) {
                setIsSuccess(true);
            }
            setIsAwaitingTransfer(false);
        }
    }, [newTransferPending, createError, incrementStep, isAwaitingTransfer]);

    const cancelTransfer = () => {
        dispatch(TransferActions.cancelTransfer({
            transferId: newTransferUuid,
            cardAccountUuid: sourceAccount?.uuid,
        }))
    }

    useEffect(() => {
        dispatch(TransferActions.clearCancelTransfer())
    }, [dispatch])

    const paymentDescription = selectedOption ? paymentDescriptions[selectedOption] : ''

    const renderStatusContent = React.useMemo(() => {
        if (createError) {
            return (
                <motion.div
                    key="error"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    style={{ width: '100%' }}
                >
                    <StatusContainer>
                        <StatusDisplay 
                            isLoading={false} 
                            isError={true} 
                            label={errors?.message || "Your payment failed due to an error. Please contact our support team for assistance."}
                        />
                    </StatusContainer>
                </motion.div>
            )
        }

        if (isAwaitingTransfer) {
            return (
                <SpinnerContainer key="loading" {...fadeInOutMotionProps}>
                    <Spinner size={40}/>
                </SpinnerContainer>
            )
        }

        if (isSuccess) {
            return (
                <motion.div
                    key="success"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    style={{ width: '100%' }}
                >
                    {isSuccess && !cancelTransferData.success && !cancelTransferData.error && (
                        <SuccessBanner message="Your payment has been submitted successfully. Thank you for your payment." />
                    )}
                    {cancelTransferData.success && (
                        <SuccessBanner message="Your payment has been cancelled successfully." />
                    )}
                    {cancelTransferData.error && (
                        <StatusDisplay 
                            isLoading={false} 
                            isError={true} 
                            label="We were unable to cancel your payment. Please contact our support team for assistance." 
                        />
                    )}
                </motion.div>
            )
        }

        return null
    }, [createError, errors?.message, isAwaitingTransfer, isSuccess, cancelTransferData.success, cancelTransferData.error])

    if (!sourceAccount) {
        return null;
    }

    return <Container {...fadeInOutMotionProps} key='review'>
        <ReviewContainer>
            <Title>Pay your Card</Title>
            <AnimatePresence mode="wait">
                {renderStatusContent}
            </AnimatePresence>

            <DetailsTable isFaded={createError || isSuccess}>
                <PaymentDetailRow
                    label="Amount"
                    showDivider
                    value={
                        <>
                            {paymentDescription} / <NumberFormat
                                value={amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'$'}
                            />
                        </>
                    }
                />
                <PaymentDetailRow 
                    label="From"
                    showTaekusIcon={sourceAccount.isInternalAccount}
                    value={getAccountName(sourceAccount)}
                />
                <PaymentDetailRow 
                    label="To"
                    showDivider
                    showTaekusIcon
                    value={selectedAccountName}
                />
                <PaymentDetailRow 
                    label="Date"
                    value={paymentDate.format('MMMM D, YYYY')}
                />
            </DetailsTable>

            <AnimatePresence>
                {!isSuccess && !createError && (
                    <motion.div
                        initial={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{ width: '100%' }}
                    >
                        <TermsContainer
                            onClick={() => { setIsCheckboxChecked(!isCheckboxChecked) }}
                        >
                            <Checkbox 
                                checked={isCheckboxChecked} 
                                onChange={() => { setIsCheckboxChecked(!isCheckboxChecked) }} 
                            />
                            <TermsText>
                                I authorize Taekus to made a one-time payment from the bank account selected above.
                            </TermsText>
                        </TermsContainer>
                    </motion.div>
                )}
            </AnimatePresence>

            <ActionSection>
                <AnimatePresence mode="wait">
                    {isSuccess || createError ? 
                        <motion.div
                            key="make-another"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            style={{ display: 'flex', gap: '16px' }}
                        >
                            <Button
                                size={ButtonSize.Standard}
                                buttonType={ButtonType.SecondaryPurple}
                                label='Make Another Payment'
                                onClick={resetForm}
                            />
                            {isCancellable && 
                                <Button 
                                    size={ButtonSize.Standard}
                                    buttonType={ButtonType.SecondaryPurple}
                                    label='Cancel Payment'
                                    onClick={cancelTransfer}
                                    disabled={cancelTransferData.submitting || cancelTransferData.success !== null}
                                />
                            }
                        </motion.div>
                    :
                        <motion.div
                            key="review-buttons"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            style={{ display: 'flex', gap: '16px' }}
                        >
                            <Button
                                size={ButtonSize.Standard}
                                buttonType={ButtonType.SecondaryPurple}
                                label='Back'
                                onClick={decrementStep}
                            />
                            <Button
                                size={ButtonSize.Standard}
                                buttonType={ButtonType.Purple}
                                label='Submit Payment'
                                onClick={handleSubmitPayment}
                                disabled={!isCheckboxChecked || isLoading}
                            />
                        </motion.div>
                    }
                </AnimatePresence>
            </ActionSection>
        </ReviewContainer>
    </Container>
}

const ReviewContainer = styled(Container)`
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin-top: 0px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 0px;
        padding-top: 24px;
        border-top: 1px solid ${styles.Color.Grey};
    }
`

type DetailsTableProps = {
    isFaded: boolean,
}

const DetailsTable = styled.div<DetailsTableProps>`
    width: 100%;
    opacity: ${props => props.isFaded ? 0.5 : 1};
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    ${styles.MediaQueries.Mobile} {
        gap: 32px;
    }
`

const TermsContainer = styled.div`
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    border: 1px solid ${styles.Color.TaekusGrey4};
    width: 100%;
    cursor: pointer;
        &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }

    ${styles.MediaQueries.Mobile} {
        border: 1px solid ${styles.Color.TaekusGrey5};
        background: ${styles.Color.White};
        backdrop-filter: blur(50px);
        gap: 16px;
        display: flex;
        align-items: flex-start;
        align-self: stretch;
    }
`

const TermsText = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    letter-spacing: 0.28px;
`
const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
    letter-spacing: 0.24px;
    display: none;

    ${styles.MediaQueries.Mobile} {
        display: block;
    }
`

const SpinnerContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${styles.Spacing.M} 0;
`

export default CreditPaymentReviewForm