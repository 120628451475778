import moment from "moment"
import { ConfirmationStep } from "components/pages/Rewards/Flights/FlightBooking/FlightBooking"

export enum TicketType {
    All='all',
    Award='award',
    Regular='regular',
}

export enum Stops {
    Nonstop='0',
    Unlimited='99',
}

export enum BookingError {
    RedemptionDisabled='redemption_disabled',
    InsufficientFunds='insufficient_funds',
    InsufficientPoints='insufficient_points',
    UnknownError='unknown_error',
}

export enum TravelClass {
    Economy='COACH',
    PremiumEconomy='PREMIUM_COACH',
    Business='BUSINESS',
    FirstClass='FIRST',
}

export enum AwardTravelClass {
    Economy='ECONOMY',
    PremiumEconomy='PREMIUM',
    Business='BUSINESS',
    FirstClass='FIRST',
}

export enum TripType {
    RoundTrip='roundTrip',
    OneWay='oneWay',
}

export type SearchOptions = {
    departureDate: moment.Moment | undefined,
    returnDate: moment.Moment | undefined,
    departureAirport: any | null,
    arrivalAirport: any | null,
    tripType: TripType,
    numberOfPassengers: string,
    ticketType: TicketType,
    maxNumberOfStops: Stops,
    airlineOption: string | null,
    travelClass: TravelClass,
    isRefundable: string,
    excludeNoCarryOn: string,
    connectingAirport: string | null,
    originIsCity: string,
    destinationIsCity: string,
    sessionId: string | null,
}

export const messages = {
    TicketSettings: {
        RoundTrip: 'Round Trip',
        Economy: 'Economy',
        Count: '1 Passenger',
    },
    Dates: {
        From: 'From',
        To: 'To',
        Range: 'Fri, Feb 4 - Sun, Feb 6',
        Button: 'Find Flights',
    },
    FareFeeInformation: {
        Refundable: {
            Included: "Refunds allowed",
            AtCharge: "Refunds allowed with a fee",
            NotOffered: "No refunds allowed"
        },
        Changeable: {
            Included: "Changes allowed",
            AtCharge: "Changes allowed with a fee",
            NotOffered: "No changes allowed"
        }
    },
    Sidebar: {
        Hotels: 'Hotels',
        CarRentals: 'Car Rentals',
        Experiences: 'Experiences',
        Shopping: 'Shopping',
        Rewards: 'Rewards',
    },
    Hotel: {
        Title: 'Find a place to stay',
        Search: 'Enter destination or hotel name',
        DatePlaceholder: '1 / 15 / 2022',
        Dropdown: 'Guests/Rooms',
        Button: 'Find Hotels',
        Subtitle: 'Popular Hotels'
    },
    Car: {
        Title: 'Find a car',
        Search: 'Enter destination or hotel name',
        Pickup: 'Pickup Date',
        Return: 'Return Date',
        Time: 'Time',
        DifferentLocation: 'Return car to a different location',
        DriverAge: 'Driver aged between 25-75',
        Button: 'Find Flights',
    },
    Places: {
        Title: 'Places and spaces'
    },
    Shop: {
        Title: 'Shop'
    },
    Rewards: {
        Title: 'Rewards',
        Note: 'Your request will be reviewed by our team within the next 14 business days. The credit will be added to your current balance after approval.',
        PointsAvailable: 'Points Available',
        PointsToRedeem: 'Number of points to redeem',
        RedeemableCashValue: 'Redeemable cash value',
        CashValue: 'Cash Value',
        Button: 'Redeem',
    },
    ConfirmationFlowSteps: {
        [ConfirmationStep.ReviewItinerary]: '',
        [ConfirmationStep.PassengerInfo]: 'Add Traveler Information',
        [ConfirmationStep.PayAndConfirm]: 'Confirm & Pay',
        [ConfirmationStep.BookingResult]: 'Booking Result',
    },
    SearchOptions: {
        Refundability: {
            Refundable: 'Refundable',
            NonRefundable: 'Non-Refundable',
        },
        ExcludeNoCarryOn: {
            Exclude: 'Exclude No-Carry-On Fares',
            Include: 'Include No-Carry-On Fares',
        },
        TripType: {
            [TripType.OneWay]: 'One Way',
            [TripType.RoundTrip]: 'Round Trip',
        },
        MaxNumberOfStops: {
            NonStop: 'Nonstop only',
            Unlimited: 'Unlimited stops',
        },
        NumberOfPassengers: [
            '1 Passenger',
            '2 Passengers',
            '3 Passengers',
            '4 Passengers',
            '5 Passengers',
            '6 Passengers',
            '7 Passengers',
            '8 Passengers',
            '9 Passengers',
        ],
        TicketType: {
            All: 'Show all flights',
            Award: 'Only show award flights',
        },
        TravelClass: {
            [TravelClass.Economy]: 'Economy',
            [TravelClass.PremiumEconomy]: 'Premium Economy',
            [TravelClass.FirstClass]: 'First Class',
            [TravelClass.Business]: 'Business',
        },
    }
}

export const enumeratedSearchOptions = {
    excludeNoCarryOn: [
        { label: messages.SearchOptions.ExcludeNoCarryOn.Exclude, value: 'true' },
        { label: messages.SearchOptions.ExcludeNoCarryOn.Include, value: 'false' },
    ],
    isRefundable: [
        { label: messages.SearchOptions.Refundability.Refundable, value: 'true' },
        { label: messages.SearchOptions.Refundability.NonRefundable, value: 'false' },
    ],
    numberOfPassengers: [
        { label: messages.SearchOptions.NumberOfPassengers[0], value: '1' },
        { label: messages.SearchOptions.NumberOfPassengers[1], value: '2' },
        { label: messages.SearchOptions.NumberOfPassengers[2], value: '3' },
        { label: messages.SearchOptions.NumberOfPassengers[3], value: '4' },
        { label: messages.SearchOptions.NumberOfPassengers[4], value: '5' },
        { label: messages.SearchOptions.NumberOfPassengers[5], value: '6' },
        { label: messages.SearchOptions.NumberOfPassengers[6], value: '7' },
        { label: messages.SearchOptions.NumberOfPassengers[7], value: '8' },
        { label: messages.SearchOptions.NumberOfPassengers[8], value: '9' },
    ],
    ticketType: [
        { label: messages.SearchOptions.TicketType.All, value: TicketType.All },
        { label: messages.SearchOptions.TicketType.Award, value: TicketType.Award },
    ],
    travelClass: [
        { label: messages.SearchOptions.TravelClass[TravelClass.Economy], value: TravelClass.Economy },
        { label: messages.SearchOptions.TravelClass[TravelClass.PremiumEconomy], value: TravelClass.PremiumEconomy },
        { label: messages.SearchOptions.TravelClass[TravelClass.Business], value: TravelClass.Business },
        { label: messages.SearchOptions.TravelClass[TravelClass.FirstClass], value: TravelClass.FirstClass },
    ],
    tripType: [
        { label: messages.SearchOptions.TripType[TripType.OneWay], value: TripType.OneWay },
        { label: messages.SearchOptions.TripType[TripType.RoundTrip], value: TripType.RoundTrip },
    ],
    maxNumberOfStops: [
        { label: messages.SearchOptions.MaxNumberOfStops.NonStop, value: Stops.Nonstop },
        { label: messages.SearchOptions.MaxNumberOfStops.Unlimited, value: Stops.Unlimited },
    ],
}

const twoWeeksFromToday = moment().startOf('date').add(2, 'week')
const threeWeeksFromToday = moment().startOf('date').add(3, 'week')
export const FlightSearchCacheTtlMs = 25 * 60 * 1000 // 25 min in milliseconds. Should be < SESSION_CACHE_TTL_SECONDS in backend

export const defaultSearchOptions = {
    departureDate: twoWeeksFromToday,
    returnDate: threeWeeksFromToday,
    departureAirport: null,
    arrivalAirport: null,
    tripType: TripType.RoundTrip,
    numberOfPassengers: "1",
    ticketType: TicketType.All,
    maxNumberOfStops: Stops.Unlimited,
    airlineOption: null,
    travelClass: TravelClass.Economy,
    isRefundable: 'false',
    excludeNoCarryOn: 'false'
} as SearchOptions

export enum ItinerarySearchDataKeys {
    Airports='airports',
    Carriers='carriers',
    Cities='cities'
}

export enum FareFeeInformation {
    Included = "included",
    AtCharge = "at_charge",
    NotOffered = "not_offered",
    Unknown='unknown'
}

export enum PriceKeys {
    PriceTotal = 'priceTotal',
    PriceBase = 'priceBase',
}

export enum SearchSource {
    Award='AWARD',
    Airgateway='AIRGATEWAY',
    Amadeus='AMADEUS',
}

export const SearchSourceToDisplayText: {[index: string]:any} = {
    AMADEUS: "gds",
    AIRGATEWAY: "ndc",
    AWARD: "award",
}