import moment from 'moment'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { motion, AnimatePresence } from "framer-motion"
import styled from 'styled-components'

import { useIsFirstRender } from 'hooks/useIsFirstRender'

import { CardAccount, CardAccountCategory, CardAccountType } from 'types/CardAccount'

import { AppPath } from 'components/appRouter/constants'
import Footer from 'components/common/Footer'
import { defaultTransactionFilterOptions, TransactionFilterOptions } from 'components/common/Transactions/constants'
import Navigation, { NavColor } from 'components/navbar/Navigation'

import { fetchTransactions } from 'components/pages/Home/homeSlice'
import TransactionTable from 'components/pages/Home/TransactionTable'

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles'

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/ArrowRight.svg";
import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";

const messages = {
    AddFunds: 'Add Funds',
    PayBill: 'Pay your Card',
    AutoPay: 'Setup autopay',
    TransactionsTitle: 'Transactions',
}

const getAnimatedCaretMotionProps = (isCaretHovered: boolean) => ({
    initial: { y: 9 },
    animate: { y: [9, -9, 9] },
    transition: { 
        ease: 'easeInOut',
        repeat: isCaretHovered ? 0 : Infinity,
        duration: isCaretHovered ? 1 : 4
    },
})

const Home = () => {
    // Redux
    const dispatch = useDispatch();
    const history = useHistory()
    const isFirstRender = useIsFirstRender()
    
    const containerRef = useRef(null);
    const parallaxBackgroundRef = useRef(null)
    const parallaxForegroundRef = useRef(null)
    const transactionsRef = useRef(null);

    // REPLACE ANY TYPING WITH TYPED REDUX STATE
    const transactions = useSelector((state: any) => state.home.transactions)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const isMoreTransactions = useSelector((state: any) => state.home.isMoreTransactions)
    const didTransactionFailParsing = useSelector((state: any) => state.home.didTransactionFailParsing)
    const banking = useSelector((state: any) => state.banking)
    const isLoadingTransactions = useSelector((state: any) => state.home.isLoadingTransactions)
    const defaultLoadedTransactionsAccountUuid = useSelector((state: any) => state.home.defaultLoadedTransactionsAccountUuid)
    const currentUser = useSelector((state: any) => state.currentUser)
    const selectedCardAccount = currentUser.currentUser.cardAccounts.find((account: CardAccount) => account.uuid === banking.account.uuid)

    // Local state
    const [transactionFilterOptions, setTransactionFilterOptions] = useState<TransactionFilterOptions>(defaultTransactionFilterOptions)
    const [transactionsPage, setTransactionsPage] = useState(0)
    const [isCaretHovered, setIsCaretHovered] = useState(false)
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false)
    const [isForegroundLoaded, setIsForegroundLoaded] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState(getDefaultBackgroundColor(selectedCardAccount?.categoryType || localStorage.getItem('recentAccountCategory'), selectedCardAccount?.productType))
    
    const isLoading = isLoadingTransactions || banking.isLoading
    const lastAccessedCategory = useMemo(() => {
        return selectedCardAccount?.categoryType || localStorage.getItem('recentAccountCategory');
      }, [
        currentUser.currentUser.cardAccounts, 
        banking.account.uuid, 
        banking.account.isLoading
    ]); // eslint-disable-line
    const areImagesLoaded = isBackgroundLoaded && isForegroundLoaded
    const isCreditAccount = banking.account.accountType === CardAccountType.Credit
    const physicalCardInactive = banking.account.needsPhysicalCardActivation

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const goToTransactions = () => {
        (containerRef.current as any).scrollTo!({ top: window.innerHeight * 0.95, behavior: 'smooth' })
    }

    const nextTransactionsPage = () => {
        setTransactionsPage(transactionsPage + 1)
    }

    const prevTransactionsPage = () => {
        setTransactionsPage(transactionsPage - 1)
    }

    const handleTransferClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        history.push(AppPath.Funding)
    }

    const handleBillPayClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        history.push(AppPath.Funding)
    }

    const handleAutoPayClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        history.push(AppPath.Funding)
    }

    // Fetch transactions whenever the user changes transaction pages
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) { 
            // page number * 100 transactions per page
            dispatch(fetchTransactions(banking.account.uuid, transactionsPage * 100, transactionFilterOptions))
        }
    }, [transactionsPage, dispatch]) // eslint-disable-line

    // Fetch transactions when transaction filters change, and reset transaction page to zero
    useEffect(() => {
        const isParamsValid = banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate

        if (!isFirstRender && isParamsValid) { 
            setTransactionsPage(0);
            dispatch(fetchTransactions(banking.account.uuid, 0, transactionFilterOptions))
        }
    }, [transactionFilterOptions, dispatch]) // eslint-disable-line

    // Fetch transactions when banking account/page loads
    useEffect(() => {
        if (banking.account.uuid && defaultLoadedTransactionsAccountUuid !== banking.account.uuid && transactionFilterOptions.startDate && transactionFilterOptions.endDate) {
            dispatch(fetchTransactions(banking.account.uuid, 0, transactionFilterOptions, true))
        }
    }, [banking.account.uuid, dispatch]) // eslint-disable-line

    const updateScrolledValue = () => {
        if (parallaxBackgroundRef.current) {
            (parallaxBackgroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 2}px`;
        }
        if (parallaxForegroundRef.current) {
            (parallaxForegroundRef.current as any).style.backgroundPositionY = `${Math.max((containerRef.current as any)?.scrollTop, 0) / 4}px`;
        }
    }

    useEffect(() => {
        (containerRef.current as any)?.addEventListener("scroll", updateScrolledValue);
    }, [])

    const getImageSources = () => {
        if (selectedCardAccount?.productType === CardAccountType.Credit) {
            return ["https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/creditHero.jpeg", ""]
        }
        switch (lastAccessedCategory) {
            case CardAccountCategory.Business:
                return ["https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/businessHero.jpeg", ""]
            case CardAccountCategory.Corporate:
                return ["https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/corporateHero.jpeg", ""]
            case CardAccountCategory.Consumer:
            default:
                return [
                    "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/home.jpg",
                    "https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/homeForeground.png"
                ]
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();
        const fgImage = new Image();

        
        bgImage.onload = () => {
            setTimeout(() => {
                if (parallaxBackgroundRef?.current) {
                    (parallaxBackgroundRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
                }
            }, 400)
            setTimeout(() => { 
                setIsBackgroundLoaded(true)
            }, 800);
        }
        bgImage.onerror = () => {
            (parallaxBackgroundRef?.current as any).style.backgroundImage = `url()`;
            setIsBackgroundLoaded(true)
        }

        fgImage.onload = () => {
            setTimeout(() => {
                if (parallaxForegroundRef?.current) {
                    (parallaxForegroundRef?.current as any).style.backgroundImage = `url(${fgImage.src})`;
                }
            }, 400)
            setTimeout(() => { 
                setIsForegroundLoaded(true)
            }, 800);
        }
        fgImage.onerror = () => {
            (parallaxForegroundRef?.current as any).style.backgroundImage = `none`;
            setIsForegroundLoaded(true)
        }

        const [ bgSrc, fgSrc ] = getImageSources()

        bgImage.src = bgSrc;
        fgImage.src = fgSrc;
    }

    useEffect(() => {
        if ((isFirstRender ? !!banking.account.uuid : currentUser.isFetching === false)) {
            setBackgroundColor(getDefaultBackgroundColor(selectedCardAccount?.categoryType, selectedCardAccount?.productType))
            setIsBackgroundLoaded(false)
            setIsForegroundLoaded(false)
            loadBackgroundImage()
            
        }
    }, [currentUser.isFetching, banking.account.uuid, banking.account.isLoading]) // eslint-disable-line

    let greetingBalance = ""
    let remainingStatementBalance = ""
    let availableCredit = ""

    const accountType = banking.account.accountType

    if (accountType === "PREPAID") {
        greetingBalance = banking.account.balance.availableBalance ? formatter.format(banking.account.balance.availableBalance) : ''
    }
    else if (accountType === "CREDIT") {
        greetingBalance = banking.account.balance.currentBalance ? formatter.format(banking.account.balance.currentBalance) : ''
        remainingStatementBalance = banking.account.balance.remainingStatementBalance ? formatter.format(banking.account.balance.remainingStatementBalance) : ''
        availableCredit = banking.account.balance.availableCredit ? formatter.format(banking.account.balance.availableCredit) : ''
    }

    return <Container ref={containerRef}>
        <Main $lastCategory={lastAccessedCategory} $backgroundColor={backgroundColor}>
            <AnimatePresence>
                <ParallaxImage 
                    key='background'
                    $isImageLoaded={areImagesLoaded}
                    ref={parallaxBackgroundRef}
                />
                <ParallaxImage 
                    key='foreground'
                    $isImageLoaded={areImagesLoaded}
                    ref={parallaxForegroundRef}
                />
            </AnimatePresence>
            <MainOverlay>
                <Navigation color={NavColor.White}/>
                <Content>
                    <GreetingContainer>
                        <AnimatePresence>
                            {(!banking.isLoading && !currentUser.isFetching) && <motion.div
                                initial={{opacity: 0, x: isMobile ? 0 : 100}}
                                exit={{opacity: 0, x: isMobile ? 0 : 100}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.7}}
                            >
                                <Greeting $isCreditAccount={isCreditAccount}>
                                    {`Hi ${currentUser.currentUser.firstName},
                                    your current balance is ${greetingBalance}`}
                                </Greeting>
                                {isCreditAccount && <CreditDetails>
                                    <Detail>
                                        <CreditHeader>Statement Balance</CreditHeader>
                                        <CreditValue>{remainingStatementBalance}</CreditValue>
                                    </Detail>
                                    <Detail>
                                        <CreditHeader>Due Date</CreditHeader>
                                        <CreditValue>{moment().date(banking.account.paymentDueDate).format('MMM D, YYYY')}</CreditValue>
                                    </Detail>
                                </CreditDetails>}
                                {physicalCardInactive && <NoteContainer $isCreditAccount={isCreditAccount}>
                                    <Note>
                                        <NoteStatus/>
                                        <div>Your physical card has shipped. <NoteLink onClick={() => { history.push(AppPath.CardSettings) }}>Activate your card to start spending</NoteLink>.</div>
                                    </Note>
                                </NoteContainer>}
                                {isCreditAccount ? <div style={{display: 'flex'}}>
                                    <ButtonContainer>
                                        <PrimaryButton onClick={handleBillPayClick}>{messages.PayBill}</PrimaryButton>
                                    </ButtonContainer>
                                    {/* <ButtonContainer>
                                        <SecondaryButton onClick={handleAutoPayClick}>{messages.AutoPay}</SecondaryButton>
                                    </ButtonContainer> */}
                                </div> : <SecondaryButton onClick={handleTransferClick}>{messages.AddFunds}</SecondaryButton>}
                            </motion.div>}
                        </AnimatePresence>
                    </GreetingContainer>
                    <TransactionsLink>
                        <TransactionsLinkLabel
                            style={{ opacity: isCaretHovered ? 1 : 0 }}
                            {...fadeInOutMotionProps}
                        >
                            Transactions
                        </TransactionsLinkLabel>
                        <CaretContainer
                            {...getAnimatedCaretMotionProps(isCaretHovered)}
                            onMouseEnter={() => { setIsCaretHovered(true) }}
                            onMouseLeave={() => { setIsCaretHovered(false) }}
                            onClick={goToTransactions}
                        >
                            <StyledCaretDown/>
                        </CaretContainer>
                    </TransactionsLink>
                </Content>
            </MainOverlay>
        </Main>
        <Transactions ref={transactionsRef}>
            <Title>{messages.TransactionsTitle}</Title>
            <AnimatePresence mode='wait'>
                <TableContainer key='transactionsTable' {...fadeInOutMotionProps}>
                    <TransactionTable
                        didTransactionFailParsing={didTransactionFailParsing}
                        updateFilterOptions={setTransactionFilterOptions}
                        filterOptions={transactionFilterOptions}
                        isLoading={isLoading}
                        transactions={transactions}
                    />
                    <CenteredFlex>
                        <PaginationButton $isVisible={!isLoading && transactionsPage > 0} onClick={prevTransactionsPage}>
                            <ArrowLeftIcon />
                        </PaginationButton>
                        <PaginationButton $isVisible={!isLoading && isMoreTransactions} onClick={nextTransactionsPage}>
                            <ArrowRightIcon />    
                        </PaginationButton>
                    </CenteredFlex>
                </TableContainer>
            </AnimatePresence>
        </Transactions>
        <Footer/>
    </Container>
}

const NoteLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

const NoteStatus = styled.div`
    width: 2px;
    height: 52px;
    background-color: ${styles.Color.TaekusPurple};
    margin-right: 16px;
`

const Note = styled.div`
    display: flex;
    align-items: center;
    max-width: 614px;
    color: #E9E9E9;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    ${styles.MediaQueries.Desktop} { 
        font-size: 24px;
    }
    ${styles.MediaQueries.Mobile} { 
        font-size: 18px;
    }
`

type NoteContainerProps = {
    $isCreditAccount: boolean,
}

const NoteContainer = styled.div<NoteContainerProps>`
    margin-bottom: ${props => props.$isCreditAccount ? 32 : 40}px;
`

const ButtonContainer = styled.div`
    &:not(&:first-child) {
        margin-left: 16px;
    }
`

const PrimaryButton = styled.button`
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: ${styles.Color.White};
    border: 1px solid ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    &:hover {
        color: ${styles.Color.White};
        background-color: transparent;
    }
    &:focus {
        outline: 2px solid ${styles.Color.TaekusPurple};
    }
    ${styles.MediaQueries.Desktop} { 
        min-width: 160px;
        padding: 20px 30px;
        font-size: 16px;
        letter-spacing: 0.32px;
    }
    ${styles.MediaQueries.Mobile} { 
        padding: 10px 25px;
        font-size: 14px;
        letter-spacing: 0.28px;
    }
    ${styles.Animation.transitionStyles}
`

const SecondaryButton = styled.button`
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: ${styles.Color.White};
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    &:hover {
        color: ${styles.Color.TaekusBlack};
        background-color: ${styles.Color.White};
    }
    &:focus {
        outline: 2px solid ${styles.Color.TaekusPurple};
    }
    ${styles.MediaQueries.Desktop} { 
        min-width: 160px;
        padding: 20px 30px;
        font-size: 16px;
        letter-spacing: 0.32px;
    }
    ${styles.MediaQueries.Mobile} { 
        padding: 10px 25px;
        font-size: 14px;
        letter-spacing: 0.28px;
    }
    ${styles.Animation.transitionStyles}
`

const MainOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 3;
`

type ParallaxImageProps = {
    $isImageLoaded: boolean,
}

const ParallaxImage = styled(motion.div)<ParallaxImageProps>`
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: 1;
    -moz-transition: opacity .3s ease-in;
    -o-transition: opacity .3s ease-in;
    -webkit-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;
    opacity: ${props => props.$isImageLoaded ? 1 : 0};
    ${styles.MediaQueries.Mobile} {
        background-position: center;
    }
`

const TransactionsLinkLabel = styled.div`
    text-align: center;
    white-space: nowrap;
    user-select: none;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const TransactionsLink = styled.div`
    color: rgba(255,255,255,0.8);
    position: absolute;
    left: calc(50vw - 20px);
    bottom: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
`

const StyledCaretDown = styled(CaretDown)`
    width: 45%;
    height: auto;
    fill: inherit;
    padding-top: 4px;
`

const CaretContainer = styled(motion.div)`
    cursor: pointer;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.S};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${styles.Animation.transitionStyles}
    border: 1px solid transparent;
    fill: rgba(255,255,255,0.6);
    &:hover {
        fill: rgb(255,255,255);
    }
`

type PaginationButtonProps = {
    $isVisible: boolean
}

const PaginationButton = styled.div<PaginationButtonProps>`
    display: ${props => props.$isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    font-size: ${styles.Spacing.S};
    line-height: ${styles.Spacing.XS};
    margin: 0 ${styles.Spacing.XS};
    margin-bottom: ${styles.Spacing.S};
    width: ${styles.Spacing.M};
    padding: 0 5px;
    height: ${styles.Spacing.M};
    border: 1px solid ${styles.Color.Black};
    cursor: pointer;
`

const TableContainer = styled(motion.div)`
    max-width: 100%;
    ${styles.MediaQueries.Desktop} {
        margin: 0 ${styles.Spacing.M};
    }
`

const CenteredFlex = styled.div`
    display: flex;
    padding: 0 ${styles.Spacing.M};
    ${styles.MediaQueries.Desktop} {
        justify-content: center;
        align-items: center;
    }
    ${styles.MediaQueries.Mobile} {
        justify-content: center;
        margin-top: ${styles.Spacing.S};
    }
`

const FundingLink = styled(Link)`
    width: fit-content;
    display: block;
    &:hover {
        text-decoration: none;
    }
`

const Title = styled.div`
    ${styles.Text.DisplayLarge}
    color: ${styles.Color.Black};
    margin-top: 50px;
    margin-bottom: ${styles.Spacing.M};
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S};
    }
`

const Transactions = styled.div`
    width: 100%;
    min-height: 100vh;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 200px;
    }
`

const GreetingContainer = styled.div`
    ${styles.MediaQueries.Desktop} {
        min-height: 104px;
    }
    ${styles.MediaQueries.Mobile} {
        min-height: 160px;
    }
`

const CreditValue = styled.div`
    color: ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Desktop} {
        min-width: 308px;
        font-size: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 50%;
        white-space: nowrap;
        font-size: 20px;
    }
`

const CreditHeader = styled.div`
    color: ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`

const Detail = styled.div`
    ${styles.MediaQueries.Mobile} {
        flex: 1;
    }
`

const CreditDetails = styled.div`
    display: flex;
    margin-bottom: 32px;
`

type GreetingProps = {
    $isCreditAccount: boolean,
}

const Greeting = styled.div<GreetingProps>`
    ${styles.Text.DisplayLarge}
    color: ${styles.Color.White};
    max-width: 100%;
    margin-bottom: ${props => props.$isCreditAccount ? 16 : 40}px;
    white-space: pre-line;
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
    }
`

const CreditInfo = styled.div`
    ${styles.Text.DisplayMedium}
    ${styles.Animation.transitionStyles}
    color: ${styles.Color.White};
    max-width: 750px;
    margin-bottom: ${styles.Spacing.L};
    white-space: pre-line;
    ${styles.MediaQueries.Mobile} {
        font-size: 1.6em;
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const getDefaultBackgroundColor = (accountCategory: string | null, accountType: string) => {
    if (accountType === CardAccountType.Credit) {
        return '#4D5F41'
    }
    switch (accountCategory) {
        case 'BUSINESS':
            return '#74A9E2'
        case 'CORPORATE':
            return '#0C0D10'
        default:
            return '#B69BAC'
    }
}

type MainProps = {
    $lastCategory: string | null,
    $backgroundColor?: string,
}

const Main = styled.div<MainProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 100%;
    background-color: ${props => props.$backgroundColor};
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    overflow: hidden;
    ${styles.Animation.transitionStyles}
`

const Content = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: end;
    color: white;
    position: relative;
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.M} 64px;
    }
    ${styles.MediaQueries.Mobile} {
        padding: 0 ${styles.Spacing.S} 40px;
    }
`

export default Home