import { createSlice } from '@reduxjs/toolkit'
import { defaultApplication, SignupStep, SignupErrorCode } from './constants'
import { Application, OnboardStatus, StepDirection } from './types'
import API from 'services/api'
import { FetchLeadError } from './errors'
import { 
    AuthenticatorBuilder, 
    DeviceRole, 
    MobileAuthImplementation,
    OtpStartInput,
    OtpFinishInput,
    OtpFinishResult,
    OtpFinishResultType,
    PhoneValidationError
} from "@prove-identity/prove-auth"
import PhoneNumberInput from '@prove-identity/prove-auth/build/lib/proveauth/internal/phone-number-input'
import { getIovationBlackboxKey } from 'utils/iovation'

declare global {
    interface Window {
        __proveOtpResolve?: (otp: string) => void;
        __proveOtpCancel?: () => void;
    }
}

type SignupState = {
    // Lead/Application model
    application: Application,
    // Expiration timestamp for MFA codes
    tokenExpiration: string | undefined,
    // boolean indicating if login requires MFA
    loginRequiresMFA: boolean | undefined,
    // uuid corresponding to the lead/application uuid
    leadUuid: string | undefined,
    // boolean indicating that a lead matching the user's info already exists
    leadExists: boolean | undefined,
    // boolean indicating a user matching the provided personal info exists
    userExists: boolean | undefined,
    // uuid corresponding to the offer (if pre-approved)
    offerUuid: string | undefined,
    // uuid corresponding to the upsell product
    upsellUuid: string | undefined,
    // boolean denoting if the provided invite code is valid or not
    isInviteCodeValid: boolean | undefined,
    // boolean denoting if the provided username is valid or not
    isUsernameValid: boolean | undefined,
    // boolean denoting if the provided password is valid or not
    isPasswordValid: boolean | undefined,
    // boolean denoting if IPRegistry detected VPN or int'l user
    isVPNOrIntl: boolean | undefined,
    // boolean denoting if the login API resulted in an error
    loginFailed: boolean,
    // enum denoting onboarding status (approved, pending, etc)
    onboardStatus: OnboardStatus | undefined,
    // boolean denoting if the onboard API resulted in an error
    onboardFailed: boolean,
    // boolean denoting if the lead patch API resulted in an error
    patchFailed: boolean,
    // boolean denoting if the create lead API resulted in an error
    leadFailed: boolean | undefined,
    // boolean denoting loading state
    isLoading: boolean,
    // enum denoting the currently displayed flow step
    step: SignupStep,
    // enum denoting the previously displayed flow step (not always set)
    prevStep?: SignupStep,
    // enum denoting the flow direction (used for animations)
    direction: StepDirection,
    // error structure for APIs
    // TODO: consolidate other API errors into this object
    errors: {
        fetchLead: FetchLeadError | undefined,
    }
    // PROVE validation data
    prove: {
        authToken: string | undefined,
        rejected: boolean | undefined,
        prefillComplete: boolean | undefined,
        invalidPhone: boolean,
        otpRequired: boolean,
        flowCancelled: boolean,
    },
    // Alloy validation data
    alloy: {
        journey_application_token: string,
        status: string,
    } | undefined,
    // approved offer data
    offer: any | undefined,
    // list of products exposed per invite code
    products: any[] | undefined,
}

const defaultSignupState: SignupState = {
    application: defaultApplication,
    tokenExpiration: undefined,
    loginRequiresMFA: undefined,
    leadUuid: undefined,
    leadExists: undefined,
    userExists: undefined,
    offerUuid: undefined,
    upsellUuid: undefined,
    isInviteCodeValid: undefined,
    isUsernameValid: undefined,
    isPasswordValid: undefined,
    isVPNOrIntl: undefined,
    loginFailed: false,
    onboardStatus: undefined,
    onboardFailed: false,
    patchFailed: false,
    leadFailed: undefined,
    isLoading: false,
    step: SignupStep.InviteCode,
    direction: StepDirection.Right,
    errors: {
        fetchLead: undefined
    },
    prove: {
        authToken: undefined,
        rejected: undefined,
        prefillComplete: undefined,
        invalidPhone: false,
        otpRequired: false,
        flowCancelled: false,
    },
    alloy: undefined,
    offer: undefined,
    products: undefined,
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState: defaultSignupState,
    reducers: {
        updateStep: (state, action) => {
            state.step = action.payload.step !== undefined ? action.payload.step : state.step
            state.prevStep = action.payload.prevStep
            state.direction = action.payload.direction !== undefined ? action.payload.direction : state.direction
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateApplication: (state, action) => {
            let copy = {
                ...state.application,
                ...action.payload
            }
            state.application = copy
        },
        updateIsInviteCodeValid: (state, action) => {
            state.isInviteCodeValid = action.payload
        },
        updateIsVPNorIntl: (state, action) => {
            state.isVPNOrIntl = action.payload
        },
        updateAlloy: (state, action) => {
            state.alloy = action.payload
        },
        updateProve: (state, action) => {
            let copy = {
                ...state.prove,
                ...action.payload
            }
            state.prove = copy
        },
        updateLeadUuid: (state, action) => {
            state.leadUuid = action.payload
        },


        // Password validation API
        validatePasswordStart: (state, action) => {
            state.isLoading = true
            state.isPasswordValid = undefined
        },
        validatePasswordError: (state, action) => {
            state.isLoading = false
            state.isPasswordValid = false
        },
        validatePasswordComplete: (state, action) => {
            state.step = SignupStep.VerifyEmail
            state.direction = StepDirection.Right
            state.isPasswordValid = true
            state.application.password = action.payload
            state.isLoading = false
        },
        
        // Username validation API
        validateUsernameStart: (state, action) => {
            state.isLoading = true
            state.isUsernameValid = undefined
        },
        validateUsernameError: (state, action) => {
            state.isLoading = false
            state.isUsernameValid = false
        },
        validateUsernameComplete: (state, action) => {
            state.isLoading = false
            state.isUsernameValid = true
            state.application.username = action.payload
            state.step = SignupStep.CreatePassword
        },

        // User login API
        userLoginStart: (state) => {
            state.isLoading = true
            state.loginFailed = false
        },
        userLoginError: (state) => {
            state.isLoading = false
            state.loginFailed = true
        },
        userLoginComplete: (state, action) => {
            state.isLoading = false
            state.loginRequiresMFA = action.payload.twoFa
        },
        
        // Invite code validation API
        validateInviteStart: (state, action) => {
            state.isLoading = true
            state.application.inviteCode = action.payload
            state.isInviteCodeValid = undefined
        },
        validateInviteComplete: (state, action) => {
            state.isInviteCodeValid = action.payload.valid
            state.products = action.payload.offerings
            state.application = { 
                ...state.application,
                productCode: action.payload.offerings.find((offering: any) => offering.uuid === action.payload.uuid).productCode
            }
            state.upsellUuid = action.payload.upsell
            state.step = SignupStep.ChooseProduct
        },

        // Create lead API
        createLeadStart: (state) => {
            state.isLoading = true
            state.leadFailed = undefined
            state.application.isPhoneVerified = undefined
            state.userExists = undefined
            state.leadUuid = undefined
            state.prove = defaultSignupState.prove
        },
        createLeadError: (state, action) => {
            if (action.payload.response.data.errorCode === SignupErrorCode.ApplicationDenied) {
                state.step = SignupStep.Denied    
            } else {
                state.step = SignupStep.FindInfo
            }
            state.leadFailed = true
            state.isLoading = false
        },
        createLeadComplete: (state, action) => {
            state.leadFailed = false
            let proveCopy = {
                ...state.prove,
                authToken: action.payload.authToken,
                rejected: action.payload.authToken === null,
            }
            state.prove = proveCopy
            state.leadUuid = action.payload.uuid
            state.leadExists = action.payload.leadExists
            state.userExists = action.payload.userExists
            state.tokenExpiration = action.payload.tokenExpirationTime
        },

        // Fetch lead API
        fetchLeadStart: (state) => {
            state.isLoading = true
            state.errors.fetchLead = undefined
        },
        fetchLeadError: (state, action) => {
            if(action.payload.errorCode === SignupErrorCode.InvalidPhoneOrToken) {
                state.prove.invalidPhone = true
                state.errors.fetchLead = FetchLeadError.InvalidPhone
            } else if (action.payload?.nonFieldErrors?.at(0)?.includes('Token Invalid')) {
                state.errors.fetchLead = FetchLeadError.InvalidToken
            } else if (action.payload?.nonFieldErrors?.at(0)?.includes('Too many attempts')) {
                state.errors.fetchLead = FetchLeadError.Throttled
            }
            state.application.isPhoneVerified = false
            state.isLoading = false
        },
        fetchLeadProveFailure: (state, action) => {
            state.tokenExpiration = action.payload.data.tokenExpirationTime
            state.direction = StepDirection.Right
            state.step = SignupStep.VerifyPhone
            state.isLoading = false
        },
        fetchLeadComplete: (state, action) => {
            state.application = {
                ...state.application,
                ...action.payload.data.lead,
                firstName: action.payload.data.lead?.firstName,
                lastName: action.payload.data.lead?.lastName,
                addressStreet: action.payload.data.lead?.addressStreet,
                addressCity: action.payload.data.lead?.addressCity,
                addressState: action.payload.data.lead?.addressState,
                addressZip: action.payload.data.lead?.addressZip,
                isPhoneVerified: true,
            }
            state.prove.prefillComplete = true;
            state.isLoading = false
        },

        // Patch lead API
        updateLeadStart: (state) => {
            state.patchFailed = false;
            state.isLoading = true
        },
        updateLeadComplete: (state) => {
            state.isLoading = false
        },
        updateLeadError: (state) => {
            state.patchFailed = true;
            state.isLoading = false
        },

        // Submit prequal / Fetch offer API
        submitPrequalComplete: (state, action) => {
            state.offer = action.payload
        },
        submitPrequalError: (state) =>{
            state.offer = false;
        },

        // Onboard user / Finalize account API
        onboardUserStart: (state) => {
            state.isLoading = true
            state.onboardFailed = false
            state.onboardStatus = undefined
        },
        onboardUserComplete: (state, action) => {
            state.isLoading = false
            state.onboardStatus = action.payload.status
        },
        onboardUserError: (state) => {
            state.isLoading = false
            state.onboardFailed = true
        },

        // Resend verification email API
        resendVerificationEmailStart: (state) => {
            state.isLoading = true
        },
        resendVerificationEmailComplete: (state) => {
            state.isLoading = false
        },
        resendVerificationEmailError: (state) => {
            state.isLoading = false
        },
    }
})

export const {
    updateStep,
    updateIsLoading,
    updateApplication,
    updateIsInviteCodeValid,
    updateIsVPNorIntl,
    updateAlloy,
    updateProve,
    updateLeadUuid,
    updateLeadStart,
    updateLeadComplete,
    updateLeadError,
    validatePasswordStart,
    validatePasswordError,
    validatePasswordComplete,
    validateUsernameStart,
    validateUsernameError,
    validateUsernameComplete,
    userLoginStart,
    userLoginError,
    userLoginComplete,
    validateInviteStart,
    validateInviteComplete,
    createLeadStart,
    createLeadError,
    createLeadComplete,
    fetchLeadStart,
    fetchLeadError,
    fetchLeadProveFailure,
    fetchLeadComplete,
    submitPrequalComplete,
    submitPrequalError,
    onboardUserStart,
    onboardUserComplete,
    onboardUserError,
    resendVerificationEmailStart,
    resendVerificationEmailComplete,
    resendVerificationEmailError,
} = signupSlice.actions

type UserLoginParams = {
    username: string,
    password: string,
    rememberMe?: boolean,
    token?: string
}

/*
 *  User login
 */
export const userLogin = (params: UserLoginParams) => async (dispatch: any) => {
    dispatch(userLoginStart())

    const {
        username,
        password,
        token
    } = params

    // If token is passed, verify token. Otherwise, default login.
    if (token !== undefined) {
        return API.auth.pverifyToken({
            username, 
            password, 
            token,
            verificationType: 'sms'
        })
        .then((response: any) => {
            // Response will contain token, unless MFA is required
            const { access, refresh } = response.data

            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
            dispatch(userLoginComplete(response.data))
        })
        .catch((response: any) => {
            dispatch(userLoginError())
        })
    } else {
        return API.auth.plogin({
            username, 
            password,
        })
        .then((response: any) => {
            // Response will contain token, unless MFA is required
            const { twoFa, needsAuthyRegistration, access, refresh } = response.data

            if (!twoFa || needsAuthyRegistration) {
                localStorage.setItem('accessToken', access)
                localStorage.setItem('refreshToken', refresh)
            }
            dispatch(userLoginComplete(response.data))
        })
        .catch((response: any) => {
            dispatch(userLoginError())
        })
    }
}

/*
 *  Validate invite code
 */
export const validateInviteCode = (inviteCode: string) => async (dispatch: any) => {
    dispatch(validateInviteStart(inviteCode))

    return API.auth.verifyInviteCode(inviteCode)
        .then((response: any) => {
            dispatch(validateInviteComplete(response.data))
        })
        .catch((e) => {
            dispatch(updateIsInviteCodeValid(false))
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

/*
 *  Validate password
 */
export const validatePassword = (password: string) => async (dispatch: any) => {
    dispatch(validatePasswordStart(password))

    return API.auth.verifyPassword({ password })
        .then((response: any) => {
            dispatch(validatePasswordComplete(password))
        })
        .catch((e) => {
            dispatch(validatePasswordError(e))
        })
}

/*
 *  Validate username
 */
export const validateUsernameUniqueness = (username: string) => async (dispatch: any) => {
    dispatch(validateUsernameStart(username))

    return API.auth.verifyUsername(username)
        .then((response: any) => {
            dispatch(validateUsernameComplete(username))
        })
        .catch((e) => {
            dispatch(validateUsernameError(e))
        })
}

/*
 *  Prove widget authentication
 */
async function authenticate(successCallback: any, isMobileWeb: boolean, authToken: string, dispatch: any) {
    // Set up the authenticator for either mobile or desktop flow.
    let builder = new AuthenticatorBuilder();
  
    // Success callback
    const verify = (authId: string) => {
        successCallback()
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });          
    }

    const instantLink = (phoneNumberNeeded: boolean, phoneValidationError: any) => {
        return new Promise<PhoneNumberInput | null>((resolve, reject) => {
          if (phoneNumberNeeded) {
            var val = prompt("Enter phone number:");
            let input = {
              phoneNumber: val,
            };
            resolve(input as PhoneNumberInput);
          } else {
            resolve(null);
          }
        });
    }

    /**
     * Handles the OTP start step for phone number collection
     * @param phoneNumberNeeded - Whether a phone number needs to be collected
     * @param phoneValidationError - Optional error from previous phone validation attempt
     * @returns Promise resolving to OtpStartInput or null
     */
    const otpStart = async (phoneNumberNeeded: boolean, phoneValidationError?: PhoneValidationError): Promise<OtpStartInput | null> => {
        return new Promise((resolve, reject) => {
            // TODO: handle phone number needed JUST IN CASE
            // We already have the phone number from the application flow,
            // so we should not need to collect it here
            if (phoneNumberNeeded) {
                // Handle phone validation error if present
                if (phoneValidationError) {
                    // Update UI to show error message for invalid phone number
                    console.error('Phone validation error:', phoneValidationError);
                }

                // Get phone number from user
                const phoneNumber = prompt("Please enter your phone number for SMS verification:");
                if (phoneNumber) {
                    resolve({ phoneNumber });
                } else {
                    reject("User canceled phone number entry");
                }
            } else {
                // Phone number already provided in Start() call
                // We should always hit this case.
                // TODO: verify this is the case
                resolve(null);
            }
        });
    }

    /**
     * Handles the OTP finish step for verification code collection
     * @param otpError - Optional error from previous OTP validation attempt
     * @returns Promise resolving to OtpFinishResult
     */
    const otpFinish = async (otpError?: PhoneValidationError): Promise<OtpFinishResult> => {
        return new Promise((resolve, reject) => {
            // If there's an OTP error, update the error state
            if (otpError) {
                dispatch(fetchLeadError({
                    errorCode: SignupErrorCode.InvalidPhoneOrToken,  // Invalid phone/token error code
                }));
            }

            // Store the resolve function and mark that OTP is required
            window.__proveOtpResolve = (otp: string) => {
                dispatch(updateProve({ 
                    otpRequired: false,
                    flowCancelled: false 
                }));
                resolve({
                    input: { otp },
                    resultType: OtpFinishResultType.OnSuccess
                });
            };

            // Store the reject function for cancellation
            window.__proveOtpCancel = () => {
                dispatch(updateProve({ 
                    otpRequired: false,
                    flowCancelled: true 
                }));
                reject("User canceled OTP flow.");
            };
            
            // Set OTP required and token expiration (10 minutes from now)
            const expirationTime = new Date();
            expirationTime.setMinutes(expirationTime.getMinutes() + 10);
            
            dispatch(updateProve({ 
                otpRequired: true,
                flowCancelled: false 
            }));
            dispatch(createLeadComplete({
                tokenExpirationTime: expirationTime.toISOString()
            }));
        });
    }

    if (isMobileWeb) {
      // Set up Mobile Auth and OTP.
      builder = builder
        .withAuthFinishStep((input) => verify(input.authId))
        .withMobileAuthImplementation(MobileAuthImplementation.Fetch)
        .withOtpFallback(otpStart, otpFinish);
    } else {
      // Set up Instant Link.
      builder = builder
        .withAuthFinishStep((input) => verify(input.authId))
        .withInstantLinkFallback(instantLink)
        .withRole(DeviceRole.Secondary);
    }
  
    const authenticator = builder.build();
  
    // Authenticate with the authToken.
    return authenticator.authenticate(authToken);
}

type CreateLeadParams = {
    inviteCode: string,
    productCode: string,
    dateOfBirth: string,
    phoneNumber: string,
    isMobile: boolean
}

/*
 *  Create new lead
 *  note: sometimes this endpoint does not create a new lead but
 *  gives information allowing user to fetch an in-progress lead
 */
export const createLead = (params: CreateLeadParams) => async (dispatch: any) => {
    dispatch(createLeadStart())

    // Get the iovation blackbox key
    const iovationBlackboxKey = getIovationBlackboxKey();

    const payload = {
        flowType: params.isMobile ? "mobile" : "desktop",
        phone: params.phoneNumber,
        dateOfBirth: params.dateOfBirth,
        productCode: params.productCode,
        inviteCode: params.inviteCode,
        iovation_blackbox_key: iovationBlackboxKey
    };

    return API.auth.createLead(payload)
        .then((response: any) => {
            const data = response.data
            if (data.authToken) {
                // Attempt to verify via PROVE
                const authCheck = new AuthenticatorBuilder().build();
                const isMobile = authCheck.isMobileWeb()
                const successCallback = () => {
                    dispatch(fetchLead({
                        leadUuid: data.uuid,
                        phone: params.phoneNumber,
                        proveToken: data.authToken,
                    }))
                }
                authenticate(successCallback, isMobile, data.authToken, dispatch)
            }
            
            dispatch(createLeadComplete(response.data))
        })
        .catch((e) => {
            // Error handling
            dispatch(createLeadError(e))
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type UpdateLeadParams = {
    leadUuid: string,
    application: Application,
}

/*
 *  Patch an existing, open lead
 */
export const updateLead = (params: UpdateLeadParams) => async (dispatch: any) => {
    dispatch(updateLeadStart())

    return API.auth.patchLead({
        ...params.application,
        uuid: params.leadUuid,
    })
        .then((response: any) => {
            dispatch(updateLeadComplete())
        })
        .catch((e) => {
            // Error handling
            dispatch(updateLeadError())
        })
}

type FetchLeadParams = {
    leadUuid: string,
    phone: string,
    token?: string,
    proveToken?: string,
    user?: any,
}

/*
 *  Fetch an existing lead
 */
export const fetchLead = (params: FetchLeadParams) => async (dispatch: any) => {
    dispatch(fetchLeadStart())

    API.auth.getLead({
        uuid: params.leadUuid,
        phone: params.phone,
        'mfa_token': params.token,
        'authentication_token': params.proveToken
    })
        .then((response: any) => {
            if (response.data.success === false) {
                dispatch(fetchLeadProveFailure(response))
            } else {
                dispatch(fetchLeadComplete({
                    data: response.data,
                    user: params.user,
                }))
            }
        })
        .catch((e) => {
            dispatch(fetchLeadError(e?.response?.data))
        })
}

/*
 *  Submit prequal / Get offer
 */
export const submitPrequal = (leadUuid: string) => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    API.auth.postLead({ uuid: leadUuid })
        .then((response: any) => {
            dispatch(submitPrequalComplete(response.data))
        })
        .catch((e) => {
            dispatch(submitPrequalError())
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type OnboardParams = {
    uuid: string,
    username: string,
    password: string,
    cardHolderAgreementAcknowledgedUrl: string,
    creditTermsAcknowledgedUrl: string,
    debitTermsAcknowledgedUrl: string,
    electronicFundsTransferAcknowledgedUrl: string,
    electronicDisclosureAcknowledgedUrl: string,
    rewardsTermsAcknowledgedUrl: string,
    privacyPolicyAcknowledgedUrl: string,
    journeyApplicationToken?: string,
}

/*
 *  Onboard new user / Finalize card account
 */
export const onboardUser = (params: OnboardParams) => async (dispatch: any) => {
    dispatch(onboardUserStart())

    API.auth.onboardUser(params)
        .then((response: any) => {
            dispatch(onboardUserComplete(response.data))
        })
        .catch((e) => {
            dispatch(onboardUserError())
        })
}

/*
 *  Resend verification email
 */
export const resendVerificationEmail = (leadUuid: string) => async (dispatch: any) => {
    dispatch(resendVerificationEmailStart())

    return API.auth.resendVerificationEmail({ uuid: leadUuid })
        .then((response: any) => {
            dispatch(resendVerificationEmailComplete(response.data))
        })
        .catch((e) => {
            dispatch(resendVerificationEmailError())
        })
}

export default signupSlice.reducer