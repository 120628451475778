import React, {useEffect, useRef, useState} from "react"
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {parseResponse, refreshTokenGetWebsocketEndpoint, wsRequest} from "components/common/Websocket/WsUtilities";
import {FlightSearchTypes, WsJobStatus, WsMessage} from 'components/common/Websocket/WsMessageConstants'
import {v4 as uuidv4} from 'uuid';
import {
	FlightSearchCacheTtlMs,
	ItinerarySearchDataKeys, PriceKeys,
	SearchSource,
	TicketType
} from "components/pages/Rewards/constants";
import {FlightSearchCacheKey, FlightSearchCacheScope} from "components/pages/Rewards/Flights/constants";
import moment from "moment";
import {
	CalendarCell,
	ItineraryJobResponse,
	ItineraryOption,
	SearchParams
} from "components/pages/Rewards/Flights/types";
import _ from "lodash";
import {Dictionary} from "components/pages/Home/constants";

type FlightSearchWsProps = {
	// Calendar search
	calendarSearchParams: any;
	calendarResponse: any[];
	isCalendarLoading: boolean;
	setCalendarResponse: (arg: any) => void;
	setFetchedCalendarWeeks: (arg: any) => void;
	setErroredCalendarWeeks: (arg: any) => void;
	setIsCalendarLoading: (arg: any) => void;
	calendarRequestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>;

	// Common
	itinerarySearchParams: any;
	itinerarySearchResponse: any[];
	itinerarySearchData: any;
	setItinerarySearchResponse: (arg: any) => void;
	setItinerarySearchData: (arg: any) => void;

	// Itinerary search
	setIsItineraryLoading: (arg: any) => void;
	setIsItineraryError: (arg: any) => void;
	itineraryRequestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>;
	setItinerarySearchSessionId: (arg: any) => void;
	itinerarySearchSessionId: string | null;

	// Award search
	setIsAwardError: (arg: any) => void;
	setIsAwardLoading: (arg: any) => void;
	awardRequestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>;
	setAwardSearchSessionId: (arg: any) => void;
	awardSearchSessionId: string | null;

	// NDC search
	setIsNdcError: (arg: any) => void;
	setIsNdcLoading: (arg: any) => void;
	ndcRequestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>;
	setNdcSearchSessionId: (arg: any) => void;
	ndcSearchSessionId: string | null;
}

const FlightSearchWs = (props: FlightSearchWsProps) => {
	const heartbeatIntervalRef = useRef<number | null>(null);
	const previousHeartbeats: Record<string, number> = {};
	const socketUrl = refreshTokenGetWebsocketEndpoint("flights/search/");
	const [resendMessage, setResendMessage] = useState<boolean>(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
		shouldReconnect: (closeEvent: CloseEvent) => {
			setResendMessage(true)
			return true 
		},
		onReconnectStop: (numAttempts: number) => {
			props.setIsCalendarLoading(false)
			props.setIsItineraryLoading(false)
			props.setIsAwardLoading(false)
			props.setIsNdcLoading(false)
		},
		reconnectAttempts: 3,
		reconnectInterval: 3000,
	});

	const setLowestPrice = (results: ItineraryOption[], priceKey: keyof ItineraryOption, hasAwardTicket: boolean) => {
		const lowestFare = results.reduce((a: ItineraryOption, b: ItineraryOption) => a[priceKey]! < b[priceKey]! ? a : b)
		if (!!lowestFare) {
			const lowestFareDate = lowestFare.slices[0].departureTimeDate.slice(0, 10)
			const currentLowest = props.calendarResponse.find((option: CalendarCell) => {
				return option.date === lowestFareDate
			})
			const lowestPrice= currentLowest ? Math.min(currentLowest.price, Number(lowestFare[priceKey])) : lowestFare[priceKey]
			props.setCalendarResponse([{
				date: lowestFareDate,
				price: lowestPrice,
				hasAwardTicket: hasAwardTicket
			}])
		}
	}

	const getCacheKey = (key: FlightSearchCacheKey) => {
		return `${FlightSearchCacheScope}:${key}`
	}

	const getFromCache = (key: FlightSearchCacheKey, params: SearchParams) => {
		const searchParams = _.cloneDeep(params)
		delete searchParams.sessionId
		const foundItem = localStorage.getItem(getCacheKey(key))
		if (!!foundItem) {
			const parsedItem = JSON.parse(foundItem)
			const paramsMatch = _.isEqual(searchParams, parsedItem.params)
            const timeDiff = moment.now() - Number(parsedItem.timestamp)
            const withinTime = timeDiff < FlightSearchCacheTtlMs // 30 mins in ms
			if (paramsMatch && withinTime) {
				return parsedItem
			}
		}
		return undefined
	}

	const getFromCalendarCache = (params: SearchParams) => {
		const cacheParams: SearchParams = _.cloneDeep(params);
		const weeksToFetch = cacheParams.weeksToFetch
		delete cacheParams.weeksToFetch
		delete cacheParams.departureDate
		const foundItem = getFromCache(FlightSearchCacheKey.Calendar, cacheParams)
		if (!!foundItem) {
			const weeksExist = weeksToFetch && weeksToFetch.every((week: number) => foundItem.fetchedWeeks.includes(week))
			if (weeksExist) {
				return foundItem
			}
		}
		return undefined
	}

	const setCalendarCache  = (key: FlightSearchCacheKey, params: SearchParams, data: CalendarCell[]) => {
		// Results should be common across week
		const cacheParams = _.cloneDeep(params)
		const fetchedWeeks = cacheParams.weeksToFetch || []
		delete cacheParams.weeksToFetch
		delete cacheParams.departureDate
		const existingItem = getFromCache(key, cacheParams)

		// Merge if existing items match the current search query and is not expired
		if (!!existingItem) {
			const mergedData = new Set(existingItem.data ? [...existingItem.data, ...data] : data)
			const mergedFetchedWeeks = new Set(existingItem ? [...existingItem.fetchedWeeks, ...fetchedWeeks] : params.weeksToFetch)
			localStorage.setItem(getCacheKey(key), JSON.stringify({
				timestamp: moment.now(),
				params: cacheParams,
				data: Array.from(mergedData),
				fetchedWeeks: Array.from(mergedFetchedWeeks)
			}))
		} else {
			localStorage.setItem(getCacheKey(key), JSON.stringify({
				timestamp: moment.now(),
				params: cacheParams,
				data: data,
				fetchedWeeks: fetchedWeeks
			}))
		}
	}

	const setItineraryCache  = (key: FlightSearchCacheKey, params: SearchParams, options: ItineraryOption[], sessionId: string, searchData: Dictionary<Dictionary<string>>) => {
		const copiedParams = _.cloneDeep(params)
		delete copiedParams.sessionId
		localStorage.setItem(getCacheKey(key), JSON.stringify({
			timestamp: moment.now(),
			params: copiedParams,
			itineraryOptions: options,
			searchData: searchData,
			sessionId: sessionId,
		}))
	}

	const fetchItinerary = (
		foundSearchSource: string,
		expectedSearchSource: string,
		searchType: FlightSearchTypes,
		cacheKey: FlightSearchCacheKey,
		requestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>,
		setIsLoading: (arg: boolean) => void,
		setSessionId: (arg: string) => void,
		sessionId: string | null,
	) => {
		if (foundSearchSource && foundSearchSource !== expectedSearchSource) {
			// First check if a request should be made at all
			return
		}
		const requestId = uuidv4()
		const params = {
			...props.itinerarySearchParams,
			searchType: searchType
		}
		const foundItem = getFromCache(cacheKey, params)
		if (!!foundItem) {
			if (!Object.keys(requestIdToParams.current).length) {
				setIsLoading(false)
			}
			setItineraryData(foundItem.itineraryOptions)
			setSearchData(foundItem.searchData)
			setSessionId(foundItem.sessionId)
		} else {
			if (!!sessionId) {
				params.sessionId = sessionId
			}
			setIsLoading(true)
			wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
			requestIdToParams.current[requestId] = params
		}
	}

	const saveItineraries = (
		extractedJob: ItineraryJobResponse,
		cacheKey: FlightSearchCacheKey,
		requestIdToParams: React.MutableRefObject<Dictionary<SearchParams>>,
		setIsLoading: (arg: boolean) => void,
		setIsError: (arg: boolean) => void,
		setSessionId: (arg: string) => void,
		updateCalendarKey?: PriceKeys,
		updateCalendarAward?: boolean
	) => {
		const params = requestIdToParams.current[extractedJob.requestId]
		delete requestIdToParams.current[extractedJob.requestId]
		if (!Object.keys(requestIdToParams.current).length) {
			setIsLoading(false)
		}
		if (extractedJob.status === WsJobStatus.Complete) {
			const itineraryOptions = extractedJob.data?.itineraryOptions
			setItineraryData(itineraryOptions)
			setSearchData(extractedJob.data?.searchData)
			setSessionId(extractedJob.data?.sessionId)
			setItineraryCache(
				cacheKey,
				params,
				extractedJob.data?.itineraryOptions,
				extractedJob.data?.sessionId,
				extractedJob.data?.searchData
			)
			if (!!updateCalendarKey && itineraryOptions.length) {
				setLowestPrice(itineraryOptions, updateCalendarKey, updateCalendarAward || false)
			}
		} else {
			setIsError(true)
		}
	}

	useEffect(() => {
		if (resendMessage) {
			// Resend with the same request_id. First one that returns is the one we want.
			const requests = [
				props.calendarRequestIdToParams.current,
				props.itineraryRequestIdToParams.current,
				props.awardRequestIdToParams.current,
				props.ndcRequestIdToParams.current
			]
			requests.forEach(requestIdToParam => {
				Object.entries(requestIdToParam).forEach(([requestId, params]) => {
					wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
				})
			})
			setResendMessage(false)
		}
	}, [resendMessage]) // eslint-disable-line

	useEffect(() => {
		// Makes requests for calendar search
		if (!!Object.keys(props.calendarSearchParams).length) {
			const requestId = uuidv4()
			const params = {
				...props.calendarSearchParams,
				searchType: FlightSearchTypes.Calendar
			}
			props.setFetchedCalendarWeeks((existing: number[]) => [...existing, ...params.weeksToFetch])
			const foundItem = getFromCalendarCache(params)
			if (!!foundItem) {
				props.setCalendarResponse(foundItem.data)
			}
			else {
				props.setIsCalendarLoading(true)
				wsRequest(sendMessage, WsMessage.Booking.FlightSearch, params, requestId)
				props.calendarRequestIdToParams.current[requestId] = params
			}
		}
	}, [props.calendarSearchParams]) // eslint-disable-line


	useEffect(() => {
		// Makes requests for itinerary search
		const outboundSearchSource = props.itinerarySearchParams?.outboundItinerary?.searchSource
		if (!!Object.keys(props.itinerarySearchParams).length) {
			if (props.itinerarySearchParams?.ticketType?.value !== TicketType.Award) {
				// GDS requests
				fetchItinerary(
					outboundSearchSource,
					SearchSource.Amadeus,
					FlightSearchTypes.Itinerary,
					FlightSearchCacheKey.Gds,
					props.itineraryRequestIdToParams,
					props.setIsItineraryLoading,
					props.setItinerarySearchSessionId,
					props.itinerarySearchSessionId
				)
				// NDC requests
				fetchItinerary(
					outboundSearchSource,
					SearchSource.Airgateway,
					FlightSearchTypes.Ndc,
					FlightSearchCacheKey.Ndc,
					props.ndcRequestIdToParams,
					props.setIsNdcLoading,
					props.setNdcSearchSessionId,
					props.ndcSearchSessionId
				)
			}
			if (props.itinerarySearchParams?.ticketType?.value !== TicketType.Regular) {
				// Award requests
				fetchItinerary(
					outboundSearchSource,
					SearchSource.Award,
					FlightSearchTypes.Award,
					FlightSearchCacheKey.Award,
					props.awardRequestIdToParams,
					props.setIsAwardLoading,
					props.setAwardSearchSessionId,
					props.awardSearchSessionId
				)
			}
		}
	}, [props.itinerarySearchParams]) // eslint-disable-line

	useEffect(() => {
		// Callback useEffect that is called whenever the server responds with a message over WS
		if (lastMessage !== null) {
			const extractedJob = parseResponse(lastMessage)
			if (extractedJob.jobType === WsMessage.Booking.FlightSearch) {
				if (extractedJob.requestId in props.calendarRequestIdToParams.current) {					
					// Calendar search response processing
					const currentWeeks = props.calendarRequestIdToParams.current[extractedJob.requestId]?.weeksToFetch || []
					const params = props.calendarRequestIdToParams.current[extractedJob.requestId]
					delete props.calendarRequestIdToParams.current[extractedJob.requestId]
					if (!Object.keys(props.calendarRequestIdToParams.current).length) {
						props.setIsCalendarLoading(false)
					}
					if (extractedJob.status === WsJobStatus.Complete) {
						props.setCalendarResponse(extractedJob.data)
						setCalendarCache(
							FlightSearchCacheKey.Calendar,
							params,
							extractedJob.data,
						)
					} else {
						props.setErroredCalendarWeeks((existingWeeks: number[]) => [...existingWeeks, ...currentWeeks])
					}
				}
				else if (extractedJob.requestId in props.itineraryRequestIdToParams.current) {
					saveItineraries(
						extractedJob,
						FlightSearchCacheKey.Gds,
						props.itineraryRequestIdToParams,
						props.setIsItineraryLoading,
						props.setIsItineraryError,
						props.setItinerarySearchSessionId
					)
				}
				else if (extractedJob.requestId in props.ndcRequestIdToParams.current) {
					saveItineraries(
						extractedJob,
						FlightSearchCacheKey.Ndc,
						props.ndcRequestIdToParams,
						props.setIsNdcLoading,
						props.setIsNdcError,
						props.setNdcSearchSessionId,
						PriceKeys.PriceTotal
					)
				}
				else if (extractedJob.requestId in props.awardRequestIdToParams.current) {
					saveItineraries(
						extractedJob,
						FlightSearchCacheKey.Award,
						props.awardRequestIdToParams,
						props.setIsAwardLoading,
						props.setIsAwardError,
						props.setAwardSearchSessionId,
						PriceKeys.PriceBase
					)
				}
			}	
		}
	}, [lastMessage]); // eslint-disable-line

	const setSearchData = (incomingSearchData:  Dictionary<Dictionary<string>>) => {
		if (!incomingSearchData || !Object.keys(incomingSearchData).length) {
			return
		}

		props.setItinerarySearchData((existingSearchData: Dictionary<Dictionary<string>>) => {
			const searchDataCopy = _.cloneDeep(existingSearchData)
			const searchDataKeys = [ItinerarySearchDataKeys.Airports, ItinerarySearchDataKeys.Carriers, ItinerarySearchDataKeys.Cities]
			searchDataKeys.forEach(key => {
				if (key in incomingSearchData) {
					searchDataCopy[key] = {
						...searchDataCopy[key],
						...incomingSearchData[key]
					}
				}
			})
			return searchDataCopy
		})
	}

	const setItineraryData = (incomingItineraries: ItineraryOption[]) => {
		props.setItinerarySearchResponse((previousItineraries: ItineraryOption[]) => {
			const clonedItineraries = _.cloneDeep(previousItineraries)
			return clonedItineraries.concat(incomingItineraries).sort((a: ItineraryOption, b: ItineraryOption) => (a?.priceTotal < b?.priceTotal ? -1 : 1))
		})
	}

	useEffect(() => {
		// from https://github.com/robtaussig/react-use-websocket/issues/133 as we are not on react18 + react-use-websocket 4.0
		if (readyState === ReadyState.OPEN) {
			heartbeatIntervalRef.current = window.setInterval(() => {
				if (socketUrl) {
					const lastHeartbeat = previousHeartbeats[socketUrl];
					const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;
					// Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
					if (!lastHeartbeat || deltaFromNow > 10) {
						// Send the heartbeat message and update the heartbeat history.
						wsRequest(sendMessage, WsMessage.Common.Heartbeat, {});
						previousHeartbeats[socketUrl] = Date.now();
					}
				}
			}, 5000); // check every 5 seconds
			return () => {
				if (heartbeatIntervalRef.current) {
					clearInterval(heartbeatIntervalRef.current);
				}
			};
		}
	}, [socketUrl, readyState, sendMessage]) // eslint-disable-line

	return  <></>
}

export default FlightSearchWs
