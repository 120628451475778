export const DropdownKeys = {
    Closed: '',
    DatePicker: 'datePicker',
    Funding: {
        Account: 'funding/account',
        SourceAccount: 'funding/source-account',
        DestinationAccount: 'funding/destination-account'
    },
    Navigation: {
        CardAccount: 'navigation/cardAccount',
        Settings: 'navigation/settings',
    },
    FlightBooking: {
        PaymentAccount: 'booking/paymentAccount'
    }
}