import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { DropdownKeys } from "redux/features/global/constants"
import { updateDropdownKey } from "redux/features/global/globalSlice"
import { Actions as LinkedAccountActions } from 'redux/features/banking/linkedAccounts'

import { Locale, USDCurrencyOptions } from "utils/constants"

import StatusDisplay from "components/common/StatusDisplay"

import { BookingError } from "components/pages/Rewards/constants"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/Checkmark.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";

import AccountSelector from "components/common/AccountSelector/AccountSelector"
import { LinkedAccount } from "types/LinkedAccount"
import { CardAccountType } from "types/CardAccount"

type ConfirmAndPayProps = {
    selectedPaymentAccountUuid?: string,
    updateSelectedPaymentAccountUuid: (uuid: string) => void,
    selectedUpsellUuid?: string,
}

const ConfirmAndPay = (props: ConfirmAndPayProps) => {
    const dispatch = useDispatch()

    // Redux state
    const banking = useSelector((state: any) => state.banking)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const flightBook = useSelector((state: any) => state.flightBook)
    const internalAccounts = useSelector((state: any) => state.linkedAccounts.internalAccounts)

    const selectedPaymentAccount = internalAccounts.find((account: any) => account.uuid === props.selectedPaymentAccountUuid)
    const selectedFare = priceDetail.fares.find((fare: any) => fare.uuid === props.selectedUpsellUuid) || priceDetail
    const totalCash = selectedFare?.priceCashBase + selectedFare?.priceCashTaxes
    const totalPoints = selectedFare?.pricePointsBase + selectedFare?.pricePointsTaxes
    const userHasInsufficientPoints = currentUser.numPointsPending < totalPoints
    const userHasInsufficientFunds = selectedPaymentAccount?.availableBalance < totalCash
    const userLacksFunds = userHasInsufficientPoints || (totalCash !== 0 && userHasInsufficientFunds)
    const containsAwardTicket = priceDetail?.reservations?.some((reservation: any) => { return reservation.isAwardTicket})

    

    // Fetch internal accounts when component mounts
    useEffect(() => {
        dispatch(LinkedAccountActions.getInternalAccounts())
    }, [dispatch])

    // Set initial payment account UUID
    useEffect(() => {
        const { selectedPaymentAccountUuid, updateSelectedPaymentAccountUuid } = props;

        if (selectedPaymentAccountUuid === undefined) {
            updateSelectedPaymentAccountUuid(banking.account.uuid)
        }
    }, [banking.account.uuid]) // eslint-disable-line

    const getBookingErrorLabel = (error: BookingError) => {
        switch (error) {
            case BookingError.InsufficientFunds:
                return "Please select a card account with sufficient funds to pay for the taxes for this ticket."
            case BookingError.InsufficientPoints:
                return "You don't have enough points for this redemption."
            default:
                return "An error occured while attempting to book your ticket."
        }
    }

    const userCannotBook = () => {
        if (userHasInsufficientFunds) {
            return getBookingErrorLabel(BookingError.InsufficientFunds)
        } else if (userHasInsufficientPoints) {
            return getBookingErrorLabel(BookingError.InsufficientPoints)
        } else {
            return ""
        }
    }

    const { updateSelectedPaymentAccountUuid } = props;

    return <Container>
        <Title>Pay for your trip</Title>
        {priceDetail.isAwardTicket && <Header>
            <Description>Booking made possible by {priceDetail?.transferPartnerData.partnerName}.</Description>
            <Description>A final confirmation of your booking may take up to 48 hours as our partner airline works to book your itinerary.</Description>
        </Header>}
        {userLacksFunds &&  <StatusDisplay
            isLoading={false}
            isError={true}
            label={userCannotBook()}
        />}
        <DropdownsContainer>
            <PaymentContainer>
                <Subtitle>Ticket Payment Method</Subtitle>
                <PaymentDescription>{flightBook.priceDetail?.isAwardTicket && `Because this trip is a partner ticket, the ticket must be booked with points.`}</PaymentDescription>
                <DropdownInput>
                    <StyledTaekusIcon/>
                    <TicketDropdownTitle>
                        <PointAccountTitle>Taekus Points</PointAccountTitle>
                        <PointAccountBalance userHasInsufficientPoints={userHasInsufficientPoints}>
                            Available Balance: {currentUser.numPointsPending.toLocaleString()} pts
                        </PointAccountBalance>
                    </TicketDropdownTitle>
                    <TicketDropdownBalance>{totalPoints.toLocaleString()} pts</TicketDropdownBalance>
                    {userHasInsufficientPoints ? <StyledRedCross/> : <StyledCheckmark/>}
                </DropdownInput>
            </PaymentContainer>
            <PaymentContainer>
                <Subtitle>Taxes Payment Method</Subtitle>
                <PaymentDescription>{!containsAwardTicket && `This trip is not a partner ticket, so taxes are included in the point total.`}</PaymentDescription>
                {containsAwardTicket ? (
                    <AccountSelector
                        selectedAccount={selectedPaymentAccount}
                        onAccountSelect={(account: LinkedAccount): void => {
                            updateSelectedPaymentAccountUuid(account.uuid);
                        }}
                        dropdownKey={DropdownKeys.FlightBooking.PaymentAccount}
                        inputId={DropdownKeys.FlightBooking.PaymentAccount}
                        accounts={internalAccounts}
                        placeholder="Select Account"
                        includeBalanceInLabel={true}
                    />
                ) : (
                    <EmptyDropdownContainer>
                        <div className="d-flex">
                            <StyledTaekusIcon />
                            <EmptyDropdownLabel>No additional cost</EmptyDropdownLabel>
                        </div>
                        <StyledCheckmark />
                    </EmptyDropdownContainer>
                )}
            </PaymentContainer>
        </DropdownsContainer>
    </Container>
}

const EmptyDropdownLabel = styled.div`
    opacity: 0.5;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const EmptyDropdownContainer = styled.div`
    display: flex;
    border-bottom: 1px solid black;
    min-width: 300px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`

const Container = styled.div`
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        padding: 15px;
    }
`

const StyledCheckmark = styled(Checkmark)`
    width: 22px;
    height: auto;
`

const StyledRedCross = styled(Close)`
    width: 22px;
    height: auto;
    fill: red;
`

const TicketDropdownTitle = styled.div`
    flex: 2;
`

const TicketDropdownBalance = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
    white-space: nowrap;
    ${styles.MediaQueries.Desktop} {
        margin-right: 17px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-right: 8px;
    }
`

const DropdownInput = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    height: ${styles.Spacing.M};
    ${styles.MediaQueries.Desktop} {
        min-width: 300px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const PaymentContainer = styled.div`
    flex: 1;
    max-width: 360px;
    ${styles.MediaQueries.Desktop} {
        margin-right: 100px;
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Mobile} {
        &:last-child {
            margin-top: ${styles.Spacing.M};
        }
    }
`

const DropdownsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        padding-bottom: 120px;
    }
    ${styles.MediaQueries.Mobile} {
        padding-bottom: 20px;
        flex-direction: column;
    }
`

const StyledTaekusIcon = styled(TaekusIcon)`
    ${styles.MediaQueries.Desktop} {
        margin-left: 16px;
        margin-right: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Mobile} {
        margin-right: 8px;
    }
`

const Header = styled.div`
    margin-bottom: ${styles.Spacing.S};
`
const PaymentDescription = styled.div`
    color: #000;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
    height: 40px;
`

type PointAccountBalanceProps = {
    userHasInsufficientPoints: boolean,
}

const PointAccountBalance = styled.div<PointAccountBalanceProps>`
    color: ${props => props.userHasInsufficientPoints ? 'red' : '#000'};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 10.16px */
    letter-spacing: 0.08px;
`

const PointAccountTitle = styled.div`
    color: #000;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 15.24px */
    letter-spacing: 0.12px;
`

const Title = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 20px;
`

const Description = styled.div`
    color: #000;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
    margin-bottom: 6px;
`

const Subtitle = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.24px;
    white-space: nowrap;
`

export default ConfirmAndPay