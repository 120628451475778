import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { debounce } from 'utils/debounce';
import { isFeatureEnabled, ToggleFeature } from 'utils/toggles';

import { Actions as StatementActions } from 'redux/features/banking/statements'

import DisputeModal from 'components/card/transactions/disputeModal';

import DatePicker from 'components/common/DatePicker/DatePicker';
import MobileDatePickerInput from 'components/common/DatePicker/MobileDatePickerInput';
import FilterButton, { filterButtonMessages } from 'components/common/FilterButton';
import IconButton from 'components/common/IconButton';
import Spinner from 'components/common/Spinner';
import StatusDisplay from 'components/common/StatusDisplay';
import { AdvancedTransactionFilter, TransactionFilterOptions } from 'components/common/Transactions/constants';
import TransactionFilterSidebar from 'components/common/Transactions/TransactionFilterSidebar';

import TransactionItem from 'components/pages/Home/TransactionItem';
import { Transaction, TransactionType } from 'components/pages/Home/constants';

import { ReactComponent as DownloadIcon } from "assets/old/img/global/download.svg";
import { ReactComponent as CalendarSVG } from "assets/svg/Calendar.svg";
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles';

type TransactionTableProps = {
    // Boolean to determine if parsing failed
    didTransactionFailParsing: boolean,
    // An array of all transasctions to display
    transactions: Transaction[],
    // A prop that determines if loading state should be displayed
    isLoading: boolean,
    // Options passed in by the parent component (probably stored in the parent's local state)
    filterOptions: TransactionFilterOptions
    // Setter function to update the passed-in filterOptions
    updateFilterOptions: (filterOptions: TransactionFilterOptions) => void;
    filterBlacklist?: AdvancedTransactionFilter[]
}

const TransactionTable = (props: TransactionTableProps) => {
    const dispatch = useDispatch()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const banking = useSelector((state: any) => state.banking)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const isStatementDownloadLoading = useSelector((state: any) => state.statements.downloadLoading)
    const statementGeneration = useSelector((state: any) => state.statements.statementGeneration)

    // Component State
    const [showPendingTransactions, setShowPendingTransactions] = useState(true)
    const [showPostedTransactions, setShowPostedTransactions] = useState(true)
    const [disputedTransaction, setDisputedTransaction] = useState<any | undefined>(undefined)
    const [isFilterTabOpen, setIsFilterTabOpen] = useState(false)
    const [searchStringInputValue, setSearchStringInputValue] = useState('')
    const [isDebouncingSearch, setIsDebouncingSearch] = useState(false)
    const [showDateError, setShowDateError] = useState(false)
    const [dateErrorTimeoutID, setDateErrorTimeoutID] = useState<NodeJS.Timeout | null>(null)

    const isAdvancedFilteringEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.AdvancedTransactionFiltering)
    const isRefundFilteringEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.RefundTransactionFiltering)
    // Pending transactions are a) not settled, and b) not a decline
    const pendingTransactions = props.transactions.filter(transaction => (transaction.settled === false || transaction.onHold) && transaction.type !== TransactionType.Decline)
    // Posted transactions are a) settled, or b) a decline
    const postedTransactions = props.transactions.filter(transaction => (transaction.settled === true && !transaction.onHold) || transaction.type === TransactionType.Decline)
    const isEmpty = !pendingTransactions.length && !postedTransactions.length
    const maxTransactionDate = moment().startOf('day')

    const mapTransactionToRow = (transaction: Transaction, index: number) => {
        return <TransactionItem disputeTransaction={setDisputedTransaction} transaction={transaction} key={`transaction-item:${index}`}/>
    }
    
    const toggleShowPendingTransactions = () => {
        setShowPendingTransactions(!showPendingTransactions)
    }

    const toggleShowPostedTransactions = () => {
        setShowPostedTransactions(!showPostedTransactions)
    }

    const toggleFilterSidebar = () => {
        if (!isDebouncingSearch) {
            setIsFilterTabOpen(!isFilterTabOpen)
        }
    }

    const setStartDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { filterOptions, updateFilterOptions } = props;
        updateFilterOptions({
            ...filterOptions,
            startDate: moment(ev.target.value).startOf('day')
        })
    }

    const setEndDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { filterOptions, updateFilterOptions } = props;
        updateFilterOptions({
            ...filterOptions,
            endDate: moment(ev.target.value).endOf('day')
        })
    }

    const handleTransactionDateRangeChange = (dates: [Date | undefined, Date | undefined]) => { 
        const { filterOptions, updateFilterOptions } = props;
        const [start, end] = dates;

        if (!end) {
            setDateErrorTimeoutID(setTimeout(() => { 
                setShowDateError(true)
                setDateErrorTimeoutID(null)
            }, 5000))
        } else {
            setShowDateError(false)
            if (dateErrorTimeoutID) {
                clearTimeout(dateErrorTimeoutID);
                setDateErrorTimeoutID(null)
            } 
        }

        updateFilterOptions({
            ...filterOptions,
            startDate: start ? moment(start).startOf('day') : filterOptions.startDate,
            endDate: end ? moment(end).endOf('day') : undefined
        })
    }

    const updateTransactionsType = (ev: React.MouseEvent<HTMLButtonElement>) => {
        const { filterOptions, updateFilterOptions } = props;
        updateFilterOptions({
            ...filterOptions,
            type: ((ev.target as HTMLButtonElement).id as TransactionType),
        })
    }

    const updateSearchString = (searchString: string) => {
        const { updateFilterOptions, filterOptions } = props;
        setIsDebouncingSearch(false)
        updateFilterOptions({
            ...filterOptions,
            searchString,
        })
    }

    const getFilterButtonSuperScript = () => {
        const { filterOptions } = props;
        let activeFilters = 0;

        if (filterOptions.cardUuids.length) {
            activeFilters += 1;
        }

        if (filterOptions.categories.length) {
            activeFilters += 1;
        }

        if (filterOptions.minimumAmount) {
            activeFilters += 1;
        }

        if (filterOptions.maximumAmount) {
            activeFilters += 1;
        }

        return activeFilters ? activeFilters.toString() : ''
    }

    // updateSearchString needs to be updated when filterOptions changes otherwise it will reset filterOptions to the initial values
    const debouncedUpdateSearchString = useCallback(debounce(updateSearchString, 1400), [props.filterOptions]) // eslint-disable-line

    const handleSearchStringOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchStringInputValue(ev.target.value)
        setIsDebouncingSearch(true)
        debouncedUpdateSearchString(ev.target.value)
    }

    const handleLocalDownload = () => {
        if (!statementGeneration.error) {
          const fixedData = statementGeneration.data.join('')
          const url = window.URL.createObjectURL(new Blob([fixedData], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', statementGeneration.filename)
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        }
    }

    const downloadTransactions = () => {
        const { filterOptions } = props;
        if (filterOptions.startDate && filterOptions.endDate ) {
            dispatch(StatementActions.downloadStatement({
                id: '',
                custom: true,
                filetype: 'CSV',
                startDate: filterOptions.startDate?.toDate(),
                endDate: filterOptions.endDate?.toDate(),
                cardAccountUuid: banking.account.uuid,
                transactionsType: filterOptions.type,
                paymentCardIds: filterOptions.cardUuids,
            }))
        }
    }

    // Clear transaction download on component unmount
    useEffect(() => {
        return () => { dispatch(StatementActions.clearDownloadStatement()) }
    }, [dispatch])

    // Prompt browser download when statement generation is completed
    useEffect(() => {
        if (statementGeneration.success === true) {
            handleLocalDownload()
        }
    }, [statementGeneration]) // eslint-disable-line

    // Update search string display value if it's updated in the sidebar
    useEffect(() => {
        setSearchStringInputValue(props.filterOptions.searchString)
    }, [props.filterOptions.searchString])

    return <Container>
        {showDateError && !props.filterOptions.endDate && <StatusDisplay isLoading={false} isError label="Both start date and end date must be selected." />}
        <FilterOptions>
            <FilterButtonContainer>
                <FilterButton
                    id={TransactionType.All}
                    selected={props.filterOptions.type === TransactionType.All}
                    onClick={updateTransactionsType}
                >
                    {filterButtonMessages[TransactionType.All]}
                </FilterButton>
                <FilterButton 
                    id={TransactionType.Purchase}
                    selected={props.filterOptions.type === TransactionType.Purchase}
                    onClick={updateTransactionsType}
                >
                    {filterButtonMessages[TransactionType.Purchase]}
                </FilterButton>
                {isRefundFilteringEnabled && <FilterButton 
                    id={TransactionType.Refund}
                    selected={props.filterOptions.type === TransactionType.Refund}
                    onClick={updateTransactionsType}
                >
                    {filterButtonMessages[TransactionType.Refund]}
                </FilterButton>}
                <FilterButton 
                    id={TransactionType.Payment}
                    selected={props.filterOptions.type === TransactionType.Payment}
                    onClick={updateTransactionsType}
                >
                    {filterButtonMessages[TransactionType.Payment]}
                </FilterButton>
                <FilterButton 
                    id={TransactionType.Decline}
                    selected={props.filterOptions.type === TransactionType.Decline}
                    onClick={updateTransactionsType}
                >
                    {filterButtonMessages[TransactionType.Decline]}
                </FilterButton>
            </FilterButtonContainer>
            <CenteredFlex>
                <div className='d-flex'>
                    {isAdvancedFilteringEnabled && <SearchContainer>
                        <StyledSearchIcon />
                        <Search value={searchStringInputValue} onChange={handleSearchStringOnChange} placeholder='Search Transactions' />
                    </SearchContainer>}
                    {isMobile && <>
                        {isAdvancedFilteringEnabled && <FilterIconButton onClick={toggleFilterSidebar} icon={<StyledFilterIcon/> } />}
                        <DownloadButtonContainer>
                            {isStatementDownloadLoading ? <Spinner size={20}/> : <DownloadIconButton
                                disabled={props.filterOptions.endDate === undefined}
                                icon={<StyledDownloadIcon/>}
                                onClick={downloadTransactions}
                            />}
                        </DownloadButtonContainer>
                    </>}
                </div>
                {isMobile ? <div className='d-flex w-100 justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <MobileDatePickerTitle>From</MobileDatePickerTitle>
                        <CustomMobileDatePickerInput onChange={setStartDate} date={props.filterOptions.startDate!}/>
                    </div>
                    <div className='d-flex align-items-center'>
                        <MobileDatePickerTitle>To</MobileDatePickerTitle>
                        <CustomMobileDatePickerInput onChange={setEndDate} date={props.filterOptions.endDate!}/>
                    </div>
                </div> : <DatePickerContainer>
                    <DatePicker
                        id='datePicker'
                        selected={props.filterOptions.startDate}
                        startDate={props.filterOptions.startDate}
                        endDate={props.filterOptions.endDate}
                        onChange={handleTransactionDateRangeChange}
                        customInput={<DatePickerInput id='calendarInput'>
                            <CalendarIconWrapper>
                                <CalendarSVG/>
                            </CalendarIconWrapper>
                            {`${props.filterOptions.startDate?.format("MMM D")} - ${props.filterOptions.endDate ? props.filterOptions.endDate.format("MMM D") : ''}`}
                        </DatePickerInput>}
                        maxDate={maxTransactionDate}
                        selectsRange
                    />
                </DatePickerContainer>}
                {!isMobile && <div className='d-flex'>
                    {isAdvancedFilteringEnabled && <FilterIconButton
                        onClick={toggleFilterSidebar}
                        icon={<StyledFilterIcon/> }
                        superscript={getFilterButtonSuperScript()}
                    />}
                    <DownloadButtonContainer>
                        {isStatementDownloadLoading ? <Spinner size={20}/> : <DownloadIconButton
                            disabled={props.filterOptions.endDate === undefined}
                            icon={<StyledDownloadIcon/>}
                            onClick={downloadTransactions}
                        />}
                    </DownloadButtonContainer>
                </div>}
            </CenteredFlex>
        </FilterOptions>
        <AnimatePresence>
            {props.didTransactionFailParsing && <StatusDisplay isError label='An error occured while fetching your transactions. Some recent transactions may not appear.' isLoading={false} />}
        </AnimatePresence>
        <TableContainer>
            <Table>
                <TableContent {...fadeInOutMotionProps}>
                    {!isMobile && <HeaderRow>
                        <HeaderItem width='25%'>Date</HeaderItem>
                        <HeaderItem width='20%'>Description</HeaderItem>
                        <HeaderItem width='25%'>Category</HeaderItem>
                        <HeaderItem width='25%'>Amount</HeaderItem>
                        <HeaderItem width='5%' />
                    </HeaderRow>}
                    {!props.isLoading && <>
                        {!!pendingTransactions.length && <>
                            <SubHeaderRow {...fadeInOutMotionProps}>
                                {isMobile ? <>
                                    <SubHeaderItem>
                                        <div className='d-flex justify-content-between'>
                                            <div>Pending Transactions</div>
                                            <CollapseButton onClick={toggleShowPendingTransactions}>
                                                {showPendingTransactions ? '-' : '+'}
                                            </CollapseButton>
                                        </div>
                                    </SubHeaderItem>
                                </> : <>
                                    <SubHeaderItem>Pending Transactions</SubHeaderItem>
                                    <SubHeaderItem />
                                    <SubHeaderItem />
                                    <SubHeaderItem />
                                    <SubHeaderItem>
                                        <CollapseButton onClick={toggleShowPendingTransactions}>
                                            {showPendingTransactions ? '-' : '+'}
                                        </CollapseButton>
                                    </SubHeaderItem>
                                </>}
                            </SubHeaderRow>
                            {showPendingTransactions && pendingTransactions.map(mapTransactionToRow)}
                        </>}
                        {!!postedTransactions.length && <>
                            <SubHeaderRow>
                                {isMobile ? <>
                                    <SubHeaderItem>
                                        <div className='d-flex justify-content-between'>
                                            <div>Posted Transactions</div>
                                            <CollapseButton onClick={toggleShowPostedTransactions}>
                                                {showPostedTransactions ? '-' : '+'}
                                            </CollapseButton>
                                        </div>
                                    </SubHeaderItem>
                                </> : <>
                                    <SubHeaderItem>Posted Transactions</SubHeaderItem>
                                    <SubHeaderItem />
                                    <SubHeaderItem />
                                    <SubHeaderItem />
                                    <SubHeaderItem>
                                        <CollapseButton onClick={toggleShowPostedTransactions}>
                                            {showPostedTransactions ? '-' : '+'}
                                        </CollapseButton>
                                    </SubHeaderItem>
                                </>}
                            </SubHeaderRow>
                            {showPostedTransactions && postedTransactions.map(mapTransactionToRow)}
                        </>}
                    </>}
                </TableContent>
            </Table>
        </TableContainer>
        <AnimatePresence mode='wait'>
            {props.isLoading ? <SpinnerArea {...fadeInOutMotionProps}>
                <Spinner size={40}/>
                <LoadingText>Fetching Transactions</LoadingText>
            </SpinnerArea> : (isEmpty && <EmptyMessage {...fadeInOutMotionProps} animate={{opacity: 0.5}}>
                There are no transactions to display.
            </EmptyMessage>)}
        </AnimatePresence>
        {isAdvancedFilteringEnabled && <AnimatePresence>
            {isFilterTabOpen &&  <TransactionFilterSidebar 
                options={props.filterOptions}
                onOptionsChange={props.updateFilterOptions}
                onClose={toggleFilterSidebar}
                blacklist={props.filterBlacklist}
            />}
        </AnimatePresence>}
        <DisputeModal
          displayDisputeModal={!!disputedTransaction}
          setDisplayDisputeModal={setDisputedTransaction}
          transaction={disputedTransaction}
        />
    </Container>
}

const LoadingText = styled.div`
    margin-top: ${styles.Spacing.S};
    opacity: 0.5;
`

const DatePickerContainer  = styled.div`
    margin-right: ${styles.Spacing.S};
`

const StyledSearchIcon = styled(SearchIcon)`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    top: 3px;
    position: absolute;
    fill: ${styles.Color.TaekusPurple};
`

const SearchContainer = styled.div`
    position: relative;
    margin-right: ${styles.Spacing.S};
    ${styles.MediaQueries.Mobile} {
        flex: 1;
        margin-bottom: ${styles.Spacing.S};
    }
`

const CalendarIconWrapper = styled.div`
    margin-left: ${styles.Spacing.XXXS};
    margin-right: 12px;
`

const MobileDatePickerTitle = styled.div`
    ${styles.Text.BodySmall}
    color: ${styles.Color.TaekusPurple};
`

const CustomMobileDatePickerInput = styled(MobileDatePickerInput)`
    width: 30%;
`

const StyledDownloadIcon = styled(DownloadIcon)`
    stroke: inherit;
`

type DownloadIconButtonProps = {
    disabled?: boolean
}

const DownloadIconButton = styled(IconButton)<DownloadIconButtonProps>`
    stroke: black;
    margin: 0 2px;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        ${props => !props.disabled && `&:hover {
            stroke: white;
        }`}
    }
`

const DatePickerInput = styled.div`
    ${styles.Text.BodySmall}
    width: 100%;
    border-bottom: 1px solid ${styles.Color.GreyText};
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    padding-bottom: 7px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const DownloadButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: ${styles.Spacing.M};
    width: ${styles.Spacing.M};
    align-items: center;
`

const Search = styled.input`
    ${styles.Text.BodySmall}
    border: 0;
    border-radius: 0;
    box-sizing:border-box;
    border-bottom: 1px solid ${styles.Color.GreyText};
    background-color: ${styles.Color.Transparent};
    outline: none;
    color: ${styles.Color.Black};
    height: 31px;
    width: 100%;
    padding-bottom: 7px;
    padding-left: 28px;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const FilterIconButton = styled(IconButton)`
    fill: black;
    margin: 0 2px;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        &:hover {
            fill: white;
        }
    }
`

const StyledFilterIcon = styled(FilterIcon)`
    cursor: pointer;
    width: 16px;
    height: auto;
    fill: inherit;
`

const CenteredFlex = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        justify-content: center;
        align-items: center;
    }
    ${styles.MediaQueries.Mobile} {
        justify-content: center;
        margin-top: ${styles.Spacing.S};
        flex-direction: column;
        margin: ${styles.Spacing.S};
    }
`

const FilterOptions = styled.div`
    ${styles.Animation.transitionStyles}
    width: 100%;
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ${styles.Spacing.S};
        flex-wrap: wrap;
    }
`

const FilterButtonContainer = styled.div`
    display: flex;
    overflow-x: auto;
    ${styles.Scrollbar.transparent}
    ${styles.MediaQueries.Desktop} {
        padding-bottom: 10px;
    }
    ${styles.MediaQueries.Mobile} {
        mask-image: linear-gradient(to right, transparent, black ${styles.Spacing.XS}, black calc(100% - ${styles.Spacing.XS}), transparent 100%);
        padding: 0 ${styles.Spacing.S};
    }
`

const SpinnerArea = styled(motion.div)`
    width: 100%;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`

const TableContent = styled.tbody`
    ${styles.Animation.transitionStyles}
`

const CollapseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${styles.BorderRadius.Half};
    width: 24px;
    height: 24px;
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: lightgrey;
    }
`

type SubHeaderItemProps = {
    width?: string
}

const SubHeaderItem = styled.th<SubHeaderItemProps>`
    ${props => props.width && `width: ${props.width};`}
    padding-bottom: ${styles.Spacing.XS};
    padding-top: ${styles.Spacing.S};
    border-bottom: 1px solid ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.NearBlack};
    height: 24px;
    width: 100%;
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    ${styles.MediaQueries.Desktop} {
        &:nth-child(1) {
            padding-left: ${styles.Spacing.XS};
        }
    }
`

const EmptyMessage = styled(motion.div)`
    ${styles.Text.HeadingMedium}    
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    ${styles.MediaQueries.Mobile} {
        text-align: center;
    }
`

const SubHeaderRow = styled(motion.tr)`
    width: 100%
`

type HeaderItemProps = {
    width: string,
}

const HeaderItem = styled.th<HeaderItemProps>`
    width: ${props => props.width};
    font-weight: ${styles.Font.Weight[500]};
    color: ${styles.Color.NearBlack};
    border-bottom: 2px solid ${styles.Color.Black};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    &:nth-child(1) {
        padding-left: ${styles.Spacing.XS};
    }
    &:nth-child(4) {
        text-align: right;
        padding-right: ${styles.Spacing.S};
    }
`

const HeaderRow = styled.tr``

const TableContainer = styled.div`
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        margin: 0 ${styles.Spacing.S};
    }
`

const Table = styled.table`
    gap: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.M};
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
`

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
`

export default TransactionTable;