export enum TransactionType {
    All='ALL',
    Purchase='PURCHASE',
    Refund='REFUND',
    Payment='PAYMENT',
    Decline='DECLINE',
}

export interface Dictionary<T> {
    [Key: string]: T;
}

export type Transaction = {
    date: string,
    state: string,
    type: string,
    merchant: { originalDescription: string, webAddress: string, memo: string, name: string, description: string },
    categoryLabel: string,
    pointsEarned: number,
    pointsPosted: boolean,
    amount: { amountOrigin: number, currencyOrigin: string, amountUsd: number },
    settled: boolean,
    onHold: boolean,
    memo: string,
    mccCode?: string,
    interchangeEarned?: number,
    cardNickname: string,
    cardUuid: string,
    cardLast4: string,
    reason?: string,
    dispute?: { status: string, referenceNumber: string},
    uuid: string,
    transactionMetadata?: Dictionary<string>[]
}