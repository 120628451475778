import API from "services/api";
import { Actions as UserActions } from "redux/currentUser";
import { Actions as LinkedAccountActions } from "redux/features/banking/linkedAccounts";
import { Actions as BankingActions } from "redux/features/banking/banking";
import { CardAccountType } from "types/CardAccount";

const featureName = "Transfers";

const ACTION_UPDATE_VALUE = `${featureName}/UPDATE_VALUE`;
const ACTION_CLEAR_TRANSFERS = `${featureName}/CLEAR_TRANSFERS`;

const ACTION_FETCH_TRANSFERS_PARTIAL_START = `${featureName}/FETCH_TRANSFERS_PARTIAL_START`;
const ACTION_FETCH_TRANSFERS_FULL_START = `${featureName}/FETCH_TRANSFERS_FULL_START`;
const ACTION_FETCH_TRANSFERS_COMPLETE = `${featureName}/FETCH_TRANSFERS_COMPLETE`;
const ACTION_FETCH_TRANSFERS_ERROR = `${featureName}/FETCH_TRANSFERS_ERROR`;

const ACTION_CREATE_TRANSFER_START = `${featureName}/CREATE_START`;
const ACTION_CREATE_TRANSFER_COMPLETE = `${featureName}/CREATE_COMPLETE`;
const ACTION_CREATE_TRANSFER_ERROR = `${featureName}/CREATE_ERROR`;

const ACTION_CREATE_PEER_TRANSFER_START = `${featureName}/PEER/CREATE_START`;
const ACTION_CREATE_PEER_TRANSFER_COMPLETE = `${featureName}/PEER/CREATE_COMPLETE`;
const ACTION_CREATE_PEER_TRANSFER_ERROR = `${featureName}/PEER/CREATE_ERROR`;

const ACTION_CANCEL_TRANSFER_START = `${featureName}/CANCEL_START`;
const ACTION_CLEAR_CANCEL_TRANSFER = `${featureName}/CLEAR_CANCEL`;
const ACTION_CANCEL_TRANSFER_COMPLETE = `${featureName}/CANCEL_COMPLETE`;
const ACTION_CANCEL_TRANSFER_ERROR = `${featureName}/CANCEL_ERROR`;

export const Actions = {
  clearTransfers: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_TRANSFERS });
  },

  updateValue: (newValue) => ({
    type: ACTION_UPDATE_VALUE,
    payload: newValue,
  }),

  fetchTransfers:
    (params, partial = true) =>
    (dispatch) => {
      if (partial) {
        dispatch({ type: ACTION_FETCH_TRANSFERS_PARTIAL_START });
      } else {
        dispatch({ type: ACTION_FETCH_TRANSFERS_FULL_START });
      }

      return API.banking.transfers
        .fetch(params)
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_TRANSFERS_COMPLETE,
            payload: response.data,
          });
        })
        .catch((e) => {
          dispatch({
            type: ACTION_FETCH_TRANSFERS_ERROR,
            payload: e,
          });
        });
    },
  createTransfer: (transferParams) => (dispatch, getState) => {
    dispatch({
      type: ACTION_CREATE_TRANSFER_START,
      payload: transferParams,
    });

    return API.banking.transfers
      .create(transferParams)
      .then((response) => {
        const state = getState();

        // Find the destination account to check if it's a credit account
        const destinationAccount = state.linkedAccounts.internalAccounts.find(
          (account) => account.uuid === transferParams.cardAccountUuid,
        );
        const isCreditPayment =
          destinationAccount?.type === CardAccountType.Credit;

        if (transferParams.isInternalAccount) {
          if (isCreditPayment) {
            handleCreditPaymentBalanceUpdates(dispatch, transferParams);
          } else {
            handleInternalTransferBalanceUpdates(dispatch, transferParams);
          }
        }
        dispatch({
          type: ACTION_CREATE_TRANSFER_COMPLETE,
          payload: {
            transferParams,
            transferUuid: response.data.uuid,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_TRANSFER_ERROR,
          payload: { ...e.response?.data },
        });
      });
  },
  cancelTransfer: (transferParams) => (dispatch) => {
    dispatch({ type: ACTION_CANCEL_TRANSFER_START });

    return API.banking.transfers
      .delete(transferParams)
      .then((response) => {
        dispatch({
          type: ACTION_CANCEL_TRANSFER_COMPLETE,
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CANCEL_TRANSFER_ERROR,
          payload: e,
        });
      });
  },
  clearCancelTransfer: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_CANCEL_TRANSFER });
  },
  createPeerToPeerTransfer: (transferParams) => (dispatch) => {
    dispatch({
      type: ACTION_CREATE_PEER_TRANSFER_START,
      payload: transferParams,
    });

    return API.banking.transfers
      .createPeerTransfer(transferParams)
      .then((response) => {
        // Update balance under currentUser cardAccount list
        dispatch(
          UserActions.updateUserCardAccount({
            cardAccountUuid: transferParams.cardAccountUuid,
            availableBalance: -transferParams.amount,
          }),
        );
        // Update balance under cardAccount
        dispatch(
          BankingActions.hopefulUpdateCardAccountBalance({
            availableBalance: -transferParams.amount,
          }),
        );
        // Update balance under linkedAccounts internal account
        dispatch(
          LinkedAccountActions.updateInternalAccount({
            uuid: transferParams.cardAccountUuid,
            availableBalance: -transferParams.amount,
          }),
        );
        dispatch({
          type: ACTION_CREATE_PEER_TRANSFER_COMPLETE,
          payload: { ...response.data },
        });
      })
      .catch((e) => {
        dispatch({
          type: ACTION_CREATE_PEER_TRANSFER_ERROR,
          payload: { ...e.response.data },
        });
      });
  },
};

// Helper functions for balance updates
const handleCreditPaymentBalanceUpdates = (dispatch, transferParams) => {
  const transferAmount = Number(transferParams.transferValue);
  // 1. Update source account balance (money going out)
  updateAccountBalances(dispatch, {
    cardAccountUuid: transferParams.externalAccountUuid,
    balanceChange: -transferAmount,
  });

  // 2. Update credit account balance (paying down credit)
  updateAccountBalances(dispatch, {
    cardAccountUuid: transferParams.cardAccountUuid,
    balanceChange: -transferAmount,
  });
};

const handleInternalTransferBalanceUpdates = (dispatch, transferParams) => {
  const transferAmount = Number(transferParams.transferValue);
  const isInbound = transferParams.transferDirection === "inbound";

  // Calculate balance changes
  const sourceBalanceChange = transferAmount * (isInbound ? 1 : -1);
  const destinationBalanceChange = transferAmount * (isInbound ? -1 : 1);

  // Update source account balances
  updateAccountBalances(dispatch, {
    cardAccountUuid: transferParams.cardAccountUuid,
    balanceChange: sourceBalanceChange,
  });

  // Update destination account balances
  updateAccountBalances(dispatch, {
    cardAccountUuid: transferParams.externalAccountUuid,
    balanceChange: destinationBalanceChange,
  });
};

/*
 * These actions will only apply
 * if the supplied cardAccountUuid matches the current account selection. Using this method as this reducer
 * has no access to the banking store to see what the selected account uuid is.
 *
 * We should wholly replace these 6 actions when we inevitably merge our redux stores to include all card
 * data in a single store.
 */
const updateAccountBalances = (
  dispatch,
  { cardAccountUuid, balanceChange },
) => {
  // Update in UserActions
  dispatch(
    UserActions.updateUserCardAccount({
      cardAccountUuid,
      availableBalance: balanceChange,
    }),
  );

  // Update in LinkedAccountActions
  dispatch(
    LinkedAccountActions.updateInternalAccount({
      uuid: cardAccountUuid,
      availableBalance: balanceChange,
    }),
  );

  // Update in BankingActions
  dispatch(
    BankingActions.hopefulUpdateCardAccountBalance({
      availableBalance: balanceChange,
      cardAccountUuid,
    }),
  );
};

const defaultState = {
  transfers: [],
  newTransferPending: false,
  peerTransfer: {},
  transferCreated: false,
  isLoading: false,
  error: false,
  createError: false,
  errors: [],
  cancelTransfer: {
    success: null,
    error: false,
    submitting: false,
  },
};

export const TransfersReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case ACTION_UPDATE_VALUE:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_CLEAR_TRANSFERS:
      return {
        ...defaultState,
      };

    case ACTION_FETCH_TRANSFERS_FULL_START:
      return {
        ...defaultState,
        isLoading: true,
      };

    case ACTION_FETCH_TRANSFERS_PARTIAL_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        errors: [],
      };

    case ACTION_FETCH_TRANSFERS_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case ACTION_CREATE_TRANSFER_START:
      return {
        ...state,
        newTransferPending: true,
        createError: false,
      };

    case ACTION_CREATE_TRANSFER_COMPLETE:
      return {
        ...state,
        ...action.payload.transferParams,
        createError: false,
        newTransferPending: false,
        newTransferUuid: action.payload.transferUuid,
      };

    case ACTION_CREATE_TRANSFER_ERROR:
      return {
        ...state,
        newTransferPending: false,
        isLoading: false,
        createError: true,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      };

    case ACTION_CREATE_PEER_TRANSFER_START:
      return {
        ...state,
        peerTransfer: {
          isLoading: true,
        },
      };

    case ACTION_CREATE_PEER_TRANSFER_COMPLETE:
      return {
        ...state,
        ...action.payload,
        peerTransfer: {
          ...state.peerTransfer,
          isLoading: false,
          success: true,
        },
      };

    case ACTION_CREATE_PEER_TRANSFER_ERROR:
      return {
        ...state,
        peerTransfer: {
          ...state.peerTransfer,
          isLoading: false,
          error: true,
        },
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      };

    case ACTION_FETCH_TRANSFERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      };

    case ACTION_CANCEL_TRANSFER_START:
      return {
        ...state,
        cancelTransfer: {
          ...state.cancelTransfer,
          submitting: true,
        },
      };

    case ACTION_CANCEL_TRANSFER_COMPLETE:
      return {
        ...state,
        cancelTransfer: {
          success: true,
          error: false,
          submitting: false,
        },
      };

    case ACTION_CANCEL_TRANSFER_ERROR:
      return {
        ...state,
        cancelTransfer: {
          success: false,
          error: true,
          submitting: false,
        },
      };

    case ACTION_CLEAR_CANCEL_TRANSFER:
      return {
        ...state,
        cancelTransfer: {
          success: null,
          error: false,
        },
      };

    default:
      return state;
  }
};
