// hooks/useAccountTypes.ts
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CardAccountType } from 'types/CardAccount';

interface AccountTypeInformation {
  hasCreditAccount: boolean;
  hasDebitAccount: boolean;
  hasOnlyCredit: boolean;
  hasOnlyDebit: boolean;
  isSelectedAccountCredit: boolean;
  isSelectedAccountDebit: boolean;
  isLoading: boolean;
}

export const useAccountTypeInformation = (): AccountTypeInformation => {
  const cardAccounts = useSelector((state: any) => 
    state.currentUser.currentUser.cardAccounts
  );
  const uuid = useSelector((state: any) => 
    state.banking.account.uuid
  );
  const isLoadingUser = useSelector((state: any) => 
    state.currentUser.isFetching
  );

  // Use useMemo with all dependencies to prevent unnecessary recalculations
  return useMemo(() => {
    const creditAccounts = cardAccounts.filter(
      (account: any) => account.productType === CardAccountType.Credit
    );
    const debitAccounts = cardAccounts.filter(
      (account: any) => account.productType === CardAccountType.Debit
    );

    // If uuid is null, default to the first available account type
    let selectedAccountType;
    if (uuid === null) {
      if (creditAccounts.length > 0) {
        selectedAccountType = CardAccountType.Credit;
      } else if (debitAccounts.length > 0) {
        selectedAccountType = CardAccountType.Debit;
      }
    } else {
      selectedAccountType = cardAccounts.find(
        (account: any) => account.uuid === uuid
      )?.productType;
    }

    return {
      hasCreditAccount: creditAccounts.length > 0,
      hasDebitAccount: debitAccounts.length > 0,
      hasOnlyCredit: creditAccounts.length > 0 && debitAccounts.length === 0,
      hasOnlyDebit: debitAccounts.length > 0 && creditAccounts.length === 0,
      isSelectedAccountCredit: selectedAccountType === CardAccountType.Credit,
      isSelectedAccountDebit: selectedAccountType === CardAccountType.Debit,
      isLoading: isLoadingUser || uuid === null,
    };
  }, [cardAccounts, uuid, isLoadingUser]);
};