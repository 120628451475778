import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { AnimatePresence } from "framer-motion"
import styled from "styled-components"

import { LinkedAccount } from "types/LinkedAccount"
import { DropdownKeys } from "redux/features/global/constants"
import { updateDropdownKey } from "redux/features/global/globalSlice"

import AccountsDropdown from "components/pages/Funding/Payment/AccountsDropdown"
import { getAccountLabel } from "components/pages/Funding/Payment/util"

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg"

import styles from "styles/styles"
import { CardAccountType } from "types/CardAccount"

type AccountSelectorProps = {
    /**
     * The currently selected account
     */
    selectedAccount?: LinkedAccount
    /**
     * Callback function when an account is selected
     */
    onAccountSelect: (account: LinkedAccount) => void
    /**
     * Unique key for this dropdown instance
     */
    dropdownKey: string
    /**
     * Optional label text to display above the selector
     */
    label?: string
    /**
     * List of accounts to display in the dropdown
     */
    accounts: LinkedAccount[]
    /**
     * Whether to show the account in an error state
     */
    showError?: boolean
    /**
     * Custom placeholder text when no account is selected
     */
    placeholder?: string
    /**
     * Optional ID for the input element, used for source/destination identification
     */
    inputId?: string
    /**
     * Optional custom click handler for the dropdown
     */
    onDropdownClick?: (ev: React.MouseEvent) => void
    /**
     * Optional data-testid for testing
     */
    dataTestId?: string
    /**
     * Whether to include the balance in the account label
     */
    includeBalanceInLabel?: boolean
}

/**
 * A reusable account selector component that displays a dropdown of accounts
 * and handles account selection.
 */
const AccountSelector = (props: AccountSelectorProps) => {
    const dispatch = useDispatch()
    const isDropdownOpen = useSelector((state: any) => state.global.dropdownKey === props.dropdownKey)

    const handleDropdownClick = (ev: React.MouseEvent) => {
        if (props.onDropdownClick) {
            props.onDropdownClick(ev)
        } else {
            dispatch(updateDropdownKey(isDropdownOpen ? '' : props.dropdownKey))
            ev.stopPropagation()
        }
    }

    const handleAccountSelect = (account: LinkedAccount) => {
        props.onAccountSelect(account)
        dispatch(updateDropdownKey(''))
    }

    return (
        <SelectorContainer>
            {props.label && <Label>{props.label}</Label>}
            <DropdownInputWrapper>
                <DropdownInput
                    data-testid={props.dataTestId || "accountSelector"}
                    id={props.inputId || props.dropdownKey}
                    account={props.selectedAccount}
                    onClick={handleDropdownClick}
                    onChange={(ev: any) => { ev.stopPropagation() }}
                >
                    {getAccountLabel(props.selectedAccount, (props.includeBalanceInLabel && props.selectedAccount?.type !== CardAccountType.Credit)) || props.placeholder || "Select Account"}
                </DropdownInput>
                <CaretContainer>
                    <StyledCaretDown/>
                </CaretContainer>
            </DropdownInputWrapper>
            <AnimatePresence>
                {isDropdownOpen && (
                    <AccountsDropdown
                        accounts={props.accounts}
                        onClick={handleAccountSelect}
                    />
                )}
            </AnimatePresence>
        </SelectorContainer>
    )
}

const SelectorContainer = styled.div`
    position: relative;
    width: 100%;
`

const Label = styled.div`
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.14px;
    margin-bottom: 8px;
`

const DropdownInputWrapper = styled.div`
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
`

type DropdownInputProps = {
    account?: LinkedAccount
}

const DropdownInput = styled.div<DropdownInputProps>`
    color: ${props =>  props.account === undefined ? 'rgba(0,0,0,0.5)' : styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;
        width: 416px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
        width: 100%;
        max-width: 100%;
    }
    font-style: normal;
    font-weight: 400;
    line-height: 124%;
    letter-spacing: 0.48px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${styles.Color.Black};
    caret-color: transparent;
    padding-bottom: 8px;
    padding-right: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const CaretContainer = styled.div`
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledCaretDown = styled(CaretDown)`
    margin-bottom: 8px;
    width: 12px;
    height: 12px;
`

export default AccountSelector 