import { Application } from "components/signup/types";

export const taxCategories = [
    'Agriculture',
    'Construction',
    'Finance/Real Estate',
    'Insurance Services',
    'Manufacturing',
    'Non-profit',
    'Retail Trade',
    'Wholesale Trade',
    'Other',
]

export enum SignupStep {
    InviteCode,
    ChooseProduct,
    VPNDetected,
    UserDetected,
    FindInfo,
    VerifyPhone,
    LoadingProve,
    Denied,
    UserDetails,
    InitialReview,
    Email,
    Income,
    Housing,
    BizType,
    BizName,
    BizDBA,
    BizEIN,
    BizNAICS,
    BizDetails,
    FinalReview,
    LoadingOffer,
    EditInfo,
    EditBusinessInfo,
    SignIn,
    Offer,
    ReviewDisclosure,
    Upsell,
    CreateUsername,
    CreatePassword,
    VerifyEmail,
    Welcome,
    VerificationFailure,
    KYCUpload,
}

export enum SignupErrorCode {
    InvalidPhoneOrToken = 1001,
    ApplicationDenied = 1002,
}

export const defaultApplication: Application = {
    inviteCode: undefined,
    last4SSN: undefined,
    phoneNumber: undefined,
    isPhoneVerified: undefined,
    isProveVerified: undefined,
    email: undefined,
    totalAnnualIncome: undefined,
    residenceType: undefined,
    monthlyHousingPayment: '0',
}