import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { SignupStep } from "components/signup/constants";
import { createLead, updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import TaekusLoadingIcon from "components/signup/components/TaekusLoadingIcon";

import styles from "styles/styles";

const LoadingInfo = () => {
    const dispatch = useDispatch()

    const isFirstRender = useIsFirstRender()

    const user = useSelector((state: any) => state.currentUser)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const application = useSelector((state: any) => state.signup.application)
    const prove = useSelector((state: any) => state.signup.prove)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    useEffect(() => {
        // Create lead with DOB, phone #, invite & product codes
        // This step should fail if the phone # matches an existing Taekus user

        // Don't trigger createLead if we just cancelled the Prove flow
        if (prove.flowCancelled) {
            return;
        }

        // short debounce
        setTimeout(() => {
            dispatch(createLead({
                inviteCode: application.inviteCode,
                productCode: application.productCode,
                dateOfBirth: user.userLoaded ? user.currentUser.dateOfBirth : application.birthDate,
                phoneNumber: user.userLoaded ? user.currentUser.phone : application.phoneNumber,
                isMobile
            }))
        }, 3000)
    }, []) // eslint-disable-line

    // This effect should fire when the proveData from the above BE callback is updated
    useEffect(() => {
        if (isFirstRender) {
            return
        }

        if (prove.invalidPhone) {
            // Invalid Phone #, push back to findInfo
            // props.onError()
            dispatch(updateStep({
                step: SignupStep.FindInfo,
                direction: StepDirection.Left
            }))
        } else if (prove.rejected) {
            // Prove rejected this user as a potential fraud source
            // Begin internal 2FA, and then push them to manual data entry
            dispatch(updateStep({
                step: (userExists && !user.userLoaded) ? SignupStep.UserDetected : SignupStep.VerifyPhone,
                direction: StepDirection.Right
            }))
        } else if (prove.prefillComplete) {
            // Prove pre-fills all necessary user data, push user to initial review
            dispatch(updateStep({
                step: SignupStep.InitialReview,
                direction: StepDirection.Right
            }))
        } else if (prove.otpRequired) {
            // Prove needs OTP verification
            dispatch(updateStep({
                step: SignupStep.VerifyPhone,
                direction: StepDirection.Right
            }))
        } else {
            // Prove didn't reject user, but does not have any or all data 
            // Push user to manual data entry
        }
    }, [prove]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='LoadingInfo'
    >
        <Form>
            <TaekusLoadingIcon/>
            <Title>Finding your information...</Title>
        </Form>
    </StepContainer>
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${styles.MediaQueries.Mobile} {
        width: 100dvw;
        height: calc(100dvh - 156px);
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-top: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
`

export default LoadingInfo